<template>
  <div>
    <v-app-bar app clipped-left>
      <v-spacer></v-spacer>
      <v-toolbar-title>
        <v-row>
          <v-col>
            <v-avatar class="mx-auto avatar" height="30px" width="30px">
              <img src="@/assets/logo_ic.png" alt="logo" />
            </v-avatar>
          </v-col>
          <v-col>
            <span
              >SiGPos - Sistema de Gerenciamento da Pós-Graduação do
              IC-Unicamp</span
            >
          </v-col>
        </v-row>
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-main class="mt-5">
      <validation-observer ref="observer" v-slot="{ invalid }">
        <v-container>
          <v-card outlined>
            <v-card-text>
              <v-container>
                <div class="title text-center">
                  Inscrição de aluno especial de pós graduação
                </div>
                <v-divider></v-divider>
                <br />
                <form @submit.prevent="submit" autocomplete="off">
                  <v-checkbox
                    :label="'Usar dados de teste'"
                    @change="fillWithTestValues()"
                  ></v-checkbox>

                  <v-card outlined>
                    <v-card-title class="blue lighten-5 mb-2"
                      ><v-icon class="mr-2" large color="blue">person</v-icon>
                      <h5 class="title">Dados Pessoais</h5></v-card-title
                    >
                    <v-card-text class="grey lighten-5">
                      <v-row wrap justify="start" align="start" dense>
                        <v-col cols="12" sm="12" md="2">
                          <v-text-field
                            outlined
                            block
                            label="RA"
                            placeholder="RA, se for estudante Unicamp"
                            v-model="candidate.raCandidate"
                          ></v-text-field
                        ></v-col>

                        <v-col cols="12" sm="12" md="6">
                          <validation-provider
                            name="Name"
                            mode="eager"
                            autocomplete="false"
                            v-slot="{ errors }"
                            rules="required|min:6"
                          >
                            <v-text-field
                              outlined
                              block
                              label="Nome completo*"
                              placeholder="Nome do completo do candidato"
                              :error-messages="errors"
                              v-model="candidate.name"
                              :isProgressBarVisible="isProgressBarVisible"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>

                        <v-col cols="12" sm="12" md="4">
                          <validation-provider
                            name="email"
                            mode="eager"
                            v-slot="{ errors }"
                            rules="required|email"
                          >
                            <v-text-field
                              outlined
                              block
                              label="E-mail*"
                              :error-messages="errors"
                              v-model="candidate.email"
                              :isProgressBarVisible="isProgressBarVisible"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>

                        <v-col cols="12" sm="12" md="3">
                          <validation-provider
                            name="Password"
                            mode="eager"
                            vid="candidate.password"
                            v-slot="{ errors }"
                            rules="required|min:6"
                          >
                            <v-text-field
                              outlined
                              block
                              label="Senha*"
                              :type="'password'"
                              :error-messages="errors"
                              v-model="candidate.password"
                              :isProgressBarVisible="isProgressBarVisible"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>

                        <v-col cols="12" sm="12" md="2">
                          <validation-provider
                            name="birthDate"
                            mode="eager"
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <v-text-field
                              outlined
                              block
                              label="Data de nascimento*"
                              v-mask="'##/##/####'"
                              :error-messages="errors"
                              :hint="'Formato: dd/mm/aaaa'"
                              v-model="candidate.birthDate"
                              :isProgressBarVisible="isProgressBarVisible"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>

                        <v-col cols="12" sm="12" md="2">
                          <v-select
                            outlined
                            :items="sexes"
                            label="Sexo*"
                            v-model="candidate.sex"
                          ></v-select>
                        </v-col>

                        <v-col cols="12" sm="12" md="2">
                          <v-select
                            outlined
                            :items="civilStatuses"
                            label="Estado Civil*"
                            v-model="candidate.civilStatus"
                          ></v-select>
                        </v-col>

                        <v-col cols="12" sm="12" md="2">
                          <v-select
                            outlined
                            :items="binaryChoice"
                            label="Atualmente, está trabalhando?*"
                            v-model="isEmployed"
                          ></v-select>
                        </v-col>

                        <v-col cols="12" sm="12" md="4">
                          <v-text-field
                            outlined
                            v-if="isEmployed === true"
                            block
                            label="Nome da Empresa*"
                            v-model="candidate.company"
                            :isProgressBarVisible="isProgressBarVisible"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="12" md="2">
                          <v-autocomplete
                            outlined
                            :items="countries"
                            attach
                            v-model="candidate.country"
                            label="País de origem*"
                            placeholder="Escolha um país"
                          ></v-autocomplete>
                        </v-col>

                        <v-col
                          cols="12"
                          sm="12"
                          md="3"
                          v-if="candidate.country === 'Brasil'"
                        >
                          <validation-provider
                            name="cpf"
                            mode="eager"
                            v-slot="{ errors }"
                            rules="required|cpf"
                          >
                            <v-text-field
                              outlined
                              block
                              label="CPF*"
                              v-mask="'###.###.###-##'"
                              :error-messages="errors"
                              v-model="candidate.cpf"
                              :isProgressBarVisible="isProgressBarVisible"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>

                        <v-col
                          cols="12"
                          sm="12"
                          md="5"
                          v-if="candidate.country !== 'Brasil'"
                        >
                          <v-select
                            outlined
                            :items="binaryChoice"
                            label="Adquiriu nacionalidade brasileira?*"
                            v-model="isForeignerWithBrazilianNacionality"
                          ></v-select>
                        </v-col>

                        <v-col
                          cols="12"
                          sm="12"
                          md="5"
                          v-if="candidate.country !== 'Brasil'"
                        >
                          <validation-provider
                            name="rne/crnm"
                            mode="eager"
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <v-text-field
                              outlined
                              block
                              label="RNE/CRNM*"
                              :error-messages="errors"
                              v-model="candidate.foreignId"
                              :isProgressBarVisible="isProgressBarVisible"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>

                  <v-card outlined class="mt-15">
                    <v-card-title class="amber lighten-5 mb-2"
                      ><v-icon class="mr-2" color="amber" large>mail</v-icon>
                      <h5 class="title">
                        Dados para envio de correspondência
                      </h5></v-card-title
                    >
                    <v-card-text class="grey lighten-5">
                      <v-row wrap justify="start" align="end" dense>
                        <v-col cols="12" sm="12" md="2">
                          <validation-provider
                            name="cep"
                            mode="eager"
                            v-slot="{ errors }"
                            rules="cep"
                          >
                            <v-text-field
                              outlined
                              block
                              label="CEP*"
                              v-mask="'#####-###'"
                              v-model="candidate.primary_cep"
                              :error-messages="errors"
                              @change="
                                getAddressFromAPI(candidate.primary_cep, true)
                              "
                              :isProgressBarVisible="isProgressBarVisible"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>

                        <v-col cols="12" sm="12" md="2">
                          <validation-provider
                            name="estado"
                            mode="eager"
                            v-slot="{ errors }"
                          >
                            <v-autocomplete
                              outlined
                              :items="ufs"
                              attach
                              item-text="UF"
                              v-model="candidate.primary_state"
                              label="UF*"
                              :error-messages="errors"
                            >
                              <template v-slot:no-data>
                                <v-list-item>
                                  <v-list-item-title>
                                    UF não encontrada.
                                  </v-list-item-title>
                                </v-list-item>
                              </template>
                            </v-autocomplete>
                          </validation-provider>
                        </v-col>

                        <v-col cols="12" sm="12" md="8">
                          <validation-provider
                            name="cidade"
                            mode="eager"
                            v-slot="{ errors }"
                          >
                            <v-text-field
                              outlined
                              block
                              label="Cidade*"
                              v-model="candidate.primary_city"
                              :error-messages="errors"
                              :isProgressBarVisible="isProgressBarVisible"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>

                        <v-col cols="12" sm="12" md="5">
                          <validation-provider
                            name="rua"
                            mode="eager"
                            v-slot="{ errors }"
                          >
                            <v-text-field
                              outlined
                              block
                              label="Rua/Avenida*"
                              v-model="candidate.primary_street"
                              :error-messages="errors"
                              :isProgressBarVisible="isProgressBarVisible"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>

                        <v-col cols="12" sm="12" md="2">
                          <validation-provider
                            name="home_number"
                            mode="eager"
                            v-slot="{ errors }"
                          >
                            <v-text-field
                              outlined
                              block
                              label="Número*"
                              ref="homeNumber"
                              v-model="candidate.primary_home_number"
                              v-mask="'#########'"
                              :error-messages="errors"
                              :isProgressBarVisible="isProgressBarVisible"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>

                        <v-col cols="12" sm="12" md="5">
                          <validation-provider
                            name="complemento"
                            mode="eager"
                            v-slot="{ errors }"
                          >
                            <v-text-field
                              outlined
                              block
                              label="Complemento"
                              v-model="candidate.primary_complement"
                              :error-messages="errors"
                              :isProgressBarVisible="isProgressBarVisible"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>

                        <v-col cols="12" sm="12" md="3">
                          <validation-provider
                            name="cell_phone"
                            mode="eager"
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <v-text-field
                              outlined
                              block
                              label="Telefone de contato*"
                              v-mask="'(##) #####-####'"
                              v-model="candidate.primary_cell_phone"
                              :error-messages="errors"
                              :isProgressBarVisible="isProgressBarVisible"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>

                        <v-col cols="12" sm="12" md="3">
                          <v-select
                            outlined
                            :items="binaryChoice"
                            label="Gostaria de adicionar um endereço alternativo, por segurança?*"
                            v-model="shouldAddAnotherAddress"
                          ></v-select>
                        </v-col>
                        <v-col sm="0" md="6"></v-col>

                        <v-col cols="12" sm="12" md="2">
                          <validation-provider
                            v-if="shouldAddAnotherAddress"
                            name="cep"
                            mode="eager"
                            v-slot="{ errors }"
                            rules="cep"
                          >
                            <v-text-field
                              outlined
                              block
                              label="CEP*"
                              v-mask="'#####-###'"
                              v-model="candidate.secondary_cep"
                              :error-messages="errors"
                              @change="
                                getAddressFromAPI(
                                  candidate.secondary_cep,
                                  false
                                )
                              "
                              :isProgressBarVisible="isProgressBarVisible"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>

                        <v-col cols="12" sm="12" md="1">
                          <validation-provider
                            v-if="shouldAddAnotherAddress"
                            name="estado"
                            mode="eager"
                            v-slot="{ errors }"
                          >
                            <v-autocomplete
                              outlined
                              :items="ufs"
                              attach
                              item-text="UF"
                              v-model="candidate.secondary_state"
                              label="UF*"
                              :error-messages="errors"
                            >
                              <template v-slot:no-data>
                                <v-list-item>
                                  <v-list-item-title>
                                    UF não encontrada.
                                  </v-list-item-title>
                                </v-list-item>
                              </template>
                            </v-autocomplete>
                          </validation-provider>
                        </v-col>

                        <v-col cols="12" sm="12" md="5">
                          <validation-provider
                            v-if="shouldAddAnotherAddress"
                            name="cidade"
                            mode="eager"
                            v-slot="{ errors }"
                          >
                            <v-text-field
                              outlined
                              block
                              label="Cidade*"
                              v-model="candidate.secondary_city"
                              :error-messages="errors"
                              :isProgressBarVisible="isProgressBarVisible"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>

                        <v-col cols="12" sm="12" md="4">
                          <validation-provider
                            v-if="shouldAddAnotherAddress"
                            name="rua"
                            mode="eager"
                            v-slot="{ errors }"
                          >
                            <v-text-field
                              outlined
                              block
                              label="Rua/Avenida*"
                              v-model="candidate.secondary_street"
                              :error-messages="errors"
                              :isProgressBarVisible="isProgressBarVisible"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>

                        <v-col cols="12" sm="12" md="2">
                          <validation-provider
                            v-if="shouldAddAnotherAddress"
                            name="home_number"
                            mode="eager"
                            v-slot="{ errors }"
                          >
                            <v-text-field
                              outlined
                              block
                              label="Número*"
                              ref="homeNumber"
                              v-model="candidate.secondary_home_number"
                              v-mask="'#########'"
                              :error-messages="errors"
                              :isProgressBarVisible="isProgressBarVisible"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" sm="12" md="4">
                          <validation-provider
                            v-if="shouldAddAnotherAddress"
                            name="complemento"
                            mode="eager"
                            v-slot="{ errors }"
                          >
                            <v-text-field
                              outlined
                              block
                              label="Complemento"
                              v-model="candidate.secondary_complement"
                              :error-messages="errors"
                              :isProgressBarVisible="isProgressBarVisible"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" sm="12" md="2">
                          <validation-provider
                            v-if="shouldAddAnotherAddress"
                            name="cell_phone"
                            mode="eager"
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <v-text-field
                              outlined
                              block
                              label="Telefone*"
                              v-mask="'(##) #####-####'"
                              v-model="candidate.secondary_cell_phone"
                              :error-messages="errors"
                              :isProgressBarVisible="isProgressBarVisible"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-col sm="0" md="4"></v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-select
                            outlined
                            :items="tipoDeAluno"
                            label="Tipo de Aluno*"
                            v-model="candidate.tipoDeAluno"
                            placeholder="(Escolha uma opção)"
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>

                  <v-card outlined class="mt-15">
                    <v-card-title class="green lighten-5 mb-2"
                      ><v-icon class="mr-2" large color="green">school</v-icon>
                      <h5 class="title">Formação</h5></v-card-title
                    >
                    <v-card-text class="grey lighten-5">
                      <v-row wrap justify="start" align="end" dense>
                        <v-col cols="12" sm="12" md="4">
                          <validation-provider
                            name="nivelFormacao"
                            mode="eager"
                            v-slot="{ errors }"
                          >
                            <v-autocomplete
                              outlined
                              :items="nivelFormacao"
                              attach
                              item-text="nivelFormacao"
                              v-model="candidate.nivelFormacao"
                              label="Nível de Formação*"
                              placeholder="(Escolha uma opção)"
                              :error-messages="errors"
                            >
                              <template v-slot:no-data>
                                <v-list-item>
                                  <v-list-item-title>
                                    Nível inválido.
                                  </v-list-item-title>
                                </v-list-item>
                              </template>
                            </v-autocomplete>
                          </validation-provider>
                        </v-col>

                        <v-col cols="12" sm="12" md="8">
                          <v-combobox
                            outlined
                            :items="nomeDaInstituicao"
                            attach
                            item-text="Nome da Instituição"
                            v-model="candidate.nomeDaInstituicao"
                            label="Instituição de Ensino Superior"
                            persistent-hint
                            hint="Formato: Nome (SIGLA)"
                          >
                          </v-combobox>
                        </v-col>

                        <v-col cols="12" sm="12" md="5">
                          <validation-provider
                            name="cursoSuperior"
                            mode="eager"
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <v-text-field
                              outlined
                              block
                              label="Curso*"
                              v-model="candidate.cursoSuperior"
                              :error-messages="errors"
                              :isProgressBarVisible="isProgressBarVisible"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>

                        <v-col cols="12" sm="12" md="5">
                          <validation-provider
                            name="cidadeInstituicao"
                            mode="eager"
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <v-text-field
                              outlined
                              block
                              label="Cidade da Instituição*"
                              v-model="candidate.cidadeInstituicao"
                              :error-messages="errors"
                              :isProgressBarVisible="isProgressBarVisible"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>

                        <v-col cols="12" sm="12" md="2">
                          <validation-provider
                            name="estadoInstituicao"
                            mode="eager"
                            v-slot="{ errors }"
                          >
                            <v-autocomplete
                              outlined
                              :items="ufs"
                              attach
                              item-text="UF"
                              v-model="candidate.estadoInstituicao"
                              label="UF Instituição*"
                              :error-messages="errors"
                            >
                              <template v-slot:no-data>
                                <v-list-item>
                                  <v-list-item-title>
                                    UF não encontrada.
                                  </v-list-item-title>
                                </v-list-item>
                              </template>
                            </v-autocomplete>
                          </validation-provider>
                        </v-col>

                        <v-col cols="12" sm="12" md="3">
                          <v-autocomplete
                            outlined
                            :items="countries"
                            attach
                            v-model="candidate.paisInstituicao"
                            label="País Instituição*"
                          ></v-autocomplete>
                        </v-col>

                        <v-col cols="12" sm="12" md="3">
                          <validation-provider
                            name="dataConclusao"
                            mode="eager"
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <v-text-field
                              outlined
                              block
                              label="Data de Conclusão/Previsão de Conclusão*"
                              v-mask="'##/##/####'"
                              :error-messages="errors"
                              :hint="'Formato: dd/mm/aaaa'"
                              v-model="candidate.dataConclusao"
                              :isProgressBarVisible="isProgressBarVisible"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>

                        <v-col cols="12" sm="12" md="3">
                          <validation-provider
                            name="numeroSemestreAtual"
                            mode="eager"
                            v-slot="{ errors }"
                          >
                            <v-text-field
                              outlined
                              block
                              label="Número do Semestre Atual"
                              v-mask="'##'"
                              v-model="candidate.numeroSemestreAtual"
                              :error-messages="errors"
                              :isProgressBarVisible="isProgressBarVisible"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>

                        <v-col cols="12" sm="12" md="3">
                          <v-select
                            outlined
                            :items="binaryChoice"
                            label="Você já se inscreveu anteriormente para aluno especial no IC?*"
                            v-model="jaSeInscreveuParaAlunoEspecial"
                          ></v-select>
                        </v-col>

                        <v-col cols="12" sm="12" md="5">
                          <v-select
                            v-if="jaSeInscreveuParaAlunoEspecial"
                            outlined
                            :items="statusUltimoSemestreEspecial"
                            label="Se sim, você:*"
                            v-model="candidate.statusUltimoSemestreEspecial"
                            placeholder="(Escolha uma opção)"
                          ></v-select>
                        </v-col>
                        <v-col sm="0" md="7"></v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>

                  <v-row wrap justify="center" align="center" class="mt-5">
                      <v-col cols="12" sm="12" md="6" class="text-center">
                      <v-btn
                        outlined
                        color="red accent-2"
                        label="Cancelar"
                        class="py-6 my-5"
                        @click="cancel()"
                        >Cancelar
                        <v-icon color="red" class="ml-3">cancel</v-icon></v-btn
                      >
                    </v-col>

                    <v-col cols="12" sm="12" md="6" class="text-center">
                      <v-btn
                        outlined
                        color="blue accent-2"
                        name="signup-btn"
                        label="Cadastrar"
                        class="py-6 my-5"
                        type="submit"
                        @click="submitForm()"
                        :disabled="invalid"
                        :isProgressBarVisible="isProgressBarVisible"
                        >Cadastrar
                        <v-icon color="blue" class="ml-3">done</v-icon></v-btn
                      >
                    </v-col>
                    
                    <v-col cols="12" sm="12">
                      <v-progress-linear
                        color="blue accent-2"
                        indeterminate
                        v-show="isProgressBarVisible"
                      ></v-progress-linear>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <v-label color="red">{{ message }}</v-label>
                    </v-col>
                  </v-row>
                </form>
              </v-container>
            </v-card-text>
          </v-card>
        </v-container>
      </validation-observer>
    </v-main>
    <v-footer app bottom padless fixed elevation="8">
      <v-col class="text-center pa-5" cols="12">
        <div>
          <span
            ><v-icon class="mr-1">room</v-icon>Albert Einstein, 1251 - Cidade
            Universitária, Campinas-SP CEP.: 13083-852</span
          >
        </div>
        <div>
          <span
            ><v-icon small color="green" class="mr-1">phone</v-icon>+55 19
            3521-5838</span
          ><span
            ><v-icon small color="amber" class="ml-10 mr-1">mail</v-icon
            >sec-pos@ic.unicamp.br</span
          >
        </div>
      </v-col>
    </v-footer>
    <v-snackbar v-model="snackbar" :color="color" :timeout="timeout">
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          <v-icon>clear</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { required, confirmed } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import axios from "axios";

setInteractionMode("aggressive");

extend("required", {
  ...required,
  message: "Campo obrigatório!",
});

extend("confirmed", {
  ...confirmed,
  message: "Senhas não são iguais",
});

extend("max", {
  validate(value, { max }) {
    return value.length <= max;
  },
  params: ["max"],
  message: "Máximo {max} caracteres",
});

extend("min", {
  validate(value, { min }) {
    return value.length >= min;
  },
  params: ["min"],
  message: "Mínimo {min} caracteres",
});

extend("email", {
  validate: (value) => {
    const pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(value);
  },
  message: "E-mail inválido.",
});

extend("cpf", {
  validate: (value) => {
    const pattern = /^\d{3}.?\d{3}.?\d{3}-?\d{2}$/;
    return pattern.test(value);
  },
  message: "CPF inválido",
});

extend("cep", {
  validate: (value) => {
    const pattern = /^\d{5}-\d{3}$/;
    return pattern.test(value);
  },
  message: "CEP inválido",
});

export default {
  name: "SignUpCandidate",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      color: null,
      snackbar: false,
      timeout: 10000,
      snackbarMessage: "",
      candidate: {
        raCandidate: "",
        name: "",
        email: "",
        password: "",
        cpf: "",
        foreignId: "",
        birthDate: "",
        country: "Brasil",
        sex: "",
        civilStatus: "",
        company: "",
        primary_cell_phone: "",
        primary_cep: "",
        primary_country: "",
        primary_state: "",
        primary_city: "",
        primary_street: "",
        primary_home_number: "",
        primary_complement: "",
        secondary_cell_phone: "",
        secondary_cep: "",
        secondary_country: "",
        secondary_state: "",
        secondary_city: "",
        secondary_street: "",
        secondary_home_number: "",
        secondary_complement: "",
        tipoDeAluno: "",
        nivelFormacao: "",
        nomeDaInstituicao: "",
        cursoSuperior: "",
        cidadeInstituicao: "",
        estadoInstituicao: "",
        paisInstituicao: "Brasil",
        dataConclusao: "",
        statusUltimoSemestreEspecial: "",
        numeroSemestreAtual: "",
      },
      passwordConfirmation: "",
      ufs: [
        "AC",
        "AL",
        "AM",
        "AP",
        "BA",
        "CE",
        "DF",
        "ES",
        "GO",
        "MA",
        "MG",
        "MS",
        "MT",
        "PA",
        "PB",
        "PE",
        "PI",
        "PR",
        "RJ",
        "RN",
        "RO",
        "RR",
        "RS",
        "SC",
        "SE",
        "SP",
        "TO",
      ],
      countries: [
        "Afeganistão",
        "África do Sul",
        "Albânia",
        "Alemanha",
        "Andorra",
        "Angola",
        "Anguilla",
        "Antártida",
        "Antígua e Barbuda",
        "Antilhas Holandesas",
        "Arábia Saudita",
        "Argélia",
        "Argentina",
        "Armênia",
        "Aruba",
        "Austrália",
        "Áustria",
        "Azerbaijão",
        "Bahamas",
        "Bahrein",
        "Bangladesh",
        "Barbados",
        "Belarus",
        "Bélgica",
        "Belize",
        "Benin",
        "Bermudas",
        "Bolívia",
        "Bósnia-Herzegóvina",
        "Botsuana",
        "Brasil",
        "Brunei",
        "Bulgária",
        "Burkina Fasso",
        "Burundi",
        "Butão",
        "Cabo Verde",
        "Camarões",
        "Camboja",
        "Canadá",
        "Cazaquistão",
        "Chade",
        "Chile",
        "China",
        "Chipre",
        "Cingapura",
        "Colômbia",
        "Congo",
        "Coréia do Norte",
        "Coréia do Sul",
        "Costa do Marfim",
        "Costa Rica",
        "Croácia (Hrvatska)",
        "Cuba",
        "Dinamarca",
        "Djibuti",
        "Dominica",
        "Egito",
        "El Salvador",
        "Emirados Árabes Unidos",
        "Equador",
        "Eritréia",
        "Eslováquia",
        "Eslovênia",
        "Espanha",
        "Estados Unidos",
        "Estônia",
        "Etiópia",
        "Fiji",
        "Filipinas",
        "Finlândia",
        "França",
        "Gabão",
        "Gâmbia",
        "Gana",
        "Geórgia",
        "Gibraltar",
        "Grã-Bretanha (Reino Unido, UK)",
        "Granada",
        "Grécia",
        "Groelândia",
        "Guadalupe",
        "Guam (Território dos Estados Unidos)",
        "Guatemala",
        "Guernsey",
        "Guiana",
        "Guiana Francesa",
        "Guiné",
        "Guiné Equatorial",
        "Guiné-Bissau",
        "Haiti",
        "Holanda",
        "Honduras",
        "Hong Kong",
        "Hungria",
        "Iêmen",
        "Ilha Bouvet (Território da Noruega)",
        "Ilha do Homem",
        "Ilha Natal",
        "Ilha Pitcairn",
        "Ilha Reunião",
        "Ilhas Aland",
        "Ilhas Cayman",
        "Ilhas Cocos",
        "Ilhas Comores",
        "Ilhas Cook",
        "Ilhas Faroes",
        "Ilhas Falkland (Malvinas)",
        "Ilhas Geórgia do Sul e Sandwich do Sul",
        "Ilhas Heard e McDonald (Território da Austrália)",
        "Ilhas Marianas do Norte",
        "Ilhas Marshall",
        "Ilhas Menores dos Estados Unidos",
        "Ilhas Norfolk",
        "Ilhas Seychelles",
        "Ilhas Solomão",
        "Ilhas Svalbard e Jan Mayen",
        "Ilhas Tokelau",
        "Ilhas Turks e Caicos",
        "Ilhas Virgens (Estados Unidos)",
        "Ilhas Virgens (Inglaterra)",
        "Ilhas Wallis e Futuna",
        "Índia",
        "Indonésia",
        "Irã",
        "Iraque",
        "Irlanda",
        "Islândia",
        "Israel",
        "Itália",
        "Jamaica",
        "Japão",
        "Jersey",
        "Jordânia",
        "Kênia",
        "Kiribati",
        "Kuait",
        "Laos",
        "Látvia",
        "Lesoto",
        "Líbano",
        "Libéria",
        "Líbia",
        "Liechtenstein",
        "Lituânia",
        "Luxemburgo",
        "Macau",
        "Macedônia (República Yugoslava)",
        "Madagascar",
        "Malásia",
        "Malaui",
        "Maldivas",
        "Mali",
        "Malta",
        "Marrocos",
        "Martinica",
        "Maurício",
        "Mauritânia",
        "Mayotte",
        "México",
        "Micronésia",
        "Moçambique",
        "Moldova",
        "Mônaco",
        "Mongólia",
        "Montenegro",
        "Montserrat",
        "Myanma",
        "Namíbia",
        "Nauru",
        "Nepal",
        "Nicarágua",
        "Níger",
        "Nigéria",
        "Niue",
        "Noruega",
        "Nova Caledônia",
        "Nova Zelândia",
        "Omã",
        "Palau",
        "Panamá",
        "Papua-Nova Guiné",
        "Paquistão",
        "Paraguai",
        "Peru",
        "Polinésia Francesa",
        "Polônia",
        "Porto Rico",
        "Portugal",
        "Qatar",
        "Quirguistão",
        "República Centro-Africana",
        "República Democrática do Congo",
        "República Dominicana",
        "República Tcheca",
        "Romênia",
        "Ruanda",
        "Rússia (antiga URSS) - Federação Russa",
        "Saara Ocidental",
        "Saint Vincente e Granadinas",
        "Samoa Americana",
        "Samoa Ocidental",
        "San Marino",
        "Santa Helena",
        "Santa Lúcia",
        "São Bartolomeu",
        "São Cristóvão e Névis",
        "São Martim",
        "São Tomé e Príncipe",
        "Senegal",
        "Serra Leoa",
        "Sérvia",
        "Síria",
        "Somália",
        "Sri Lanka",
        "St. Pierre and Miquelon",
        "Suazilândia",
        "Sudão",
        "Suécia",
        "Suíça",
        "Suriname",
        "Tadjiquistão",
        "Tailândia",
        "Taiwan",
        "Tanzânia",
        "Território Britânico do Oceano índico",
        "Territórios do Sul da França",
        "Territórios Palestinos Ocupados",
        "Timor Leste",
        "Togo",
        "Tonga",
        "Trinidad and Tobago",
        "Tunísia",
        "Turcomenistão",
        "Turquia",
        "Tuvalu",
        "Ucrânia",
        "Uganda",
        "Uruguai",
        "Uzbequistão",
        "Vanuatu",
        "Vaticano",
        "Venezuela",
        "Vietnã",
        "Zâmbia",
        "Zimbábue",
      ],
      sexes: ["Masculino", "Feminino", "Prefiro não informar"],
      nomeDaInstituicao: [
        "Instituto Federal do Acre (Ifac)",
        "Universidade Federal do Acre (Ufac)",
        "Faculdade Diocesana São José (FADISI)",
        "Escola Superior Aberta do Brasil (ESAB)",
        "Instituto de Ensino Superior Brasileiro (ESB)",
        "Faculdade da Amazônia Ocidental (FAAO)",
        "Faculdade Campos Elíseos (FCE)",
        "Faculdade de Administração, Ciências, Educação e Letras (FACEL)",
        "Faculdade Pitágoras (FAP)",
        "Faculdade Venda Nova do Imigrante (FAVENI)",
        "Instituição de Ensino Superior do Acre (Iesacre)",
        "Instituto de Educação e Ensino Superior Ebenézer (IEESE)",
        "Universidade da Amazônia (UNAMA)",
        "Universidade Estácio de Sá (UNESA)",
        "Centro Universitário Uninorte (Uninorte)",
        "Grupo Educacional Uninter (UNINTER)",
        "Universidade Estadual de Alagoas (Uneal)",
        "Universidade Estadual de Ciências da Saúde de Alagoas (Uncisal)",
        "Instituto Federal de Alagoas (Ifal)",
        "Universidade Federal de Alagoas (Ufal)",
        "Centro de Estudos Superiores de Maceió (CESMAC)",
        "Associação de Ensino Superior de Alagoas (FAA & IESA)",
        "Faculdade da Cidade de Maceió (FACIMA)",
        "Faculdade Pitágoras (FAP)",
        "Faculdade Integrada Tiradentes (FITS)",
        "Universidade Estadual do Amapá (Ueap)",
        "Instituto Federal do Amapá (IFAP)",
        "Universidade Federal do Amapá (UNIFAP)",
        "Centro de Ensino Superior do Amapá (CEAP)",
        "Faculdade Brasil Norte (FABRAN)",
        "Faculdade do Amapá (FAMA)",
        "Faculdade de Ensino Superior da Amazônia (FESAM)",
        "Instituto de Ensino Superior do Amapá (IESAP)",
        "Instituto Macapaense do Melhor Ensino Superior (IMMES)",
        "Faculdade Meta (META)",
        "Universidade do Estado do Amazonas (UEA)",
        "Instituto Federal do Amazonas (IFAM)",
        "Universidade Federal do Amazonas (UFAM)",
        "Instituto Nacional de Pesquisas da Amazônia (INPA)",
        "Universidade Luterana do Brasil (ULBRA)",
        "Faculdade Salesiana Dom Bosco (FSDB)",
        "Centro Universitário de Ensino Superior do Amazonas (Ciesa)",
        "Universidade Nilton Lins (UNINILTONLINS)",
        "Instituto de Ensino Superior Fucapi (Cesf)",
        "Faculdade Táhirih (FT)",
        "Faculdade Metropolitana de Manaus (Fametro)",
        "Faculdade Martha Falcão (FMF)",
        "Faculdade Literatus (Fal)",
        "Faculdade La Salle (Unisalle)",
        "Faculdade do Amazonas (Iaes)",
        "Faculdade de Odontologia de Manaus (Fom)",
        "Faculdade Boas Novas de Ciências Teológicas, Sociais e Biotecnológicas (FBNCTSB)",
        "Centro Universitário do Norte (Uninorte)",
        "Universidade Paulista (Unip)",
        "Universidade Estadual do Ceará (UECE)",
        "Universidade Regional do Cariri (URCA)",
        "Universidade Estadual Vale do Acaraú (UVA)",
        "Instituto Federal do Ceará (IFCE)",
        "Universidade Federal do Ceará (UFC)",
        "Universidade Federal de Integração Luso-Afro-Brasileira (UNILAB)",
        "Universidade Federal do Cariri (UFCA)",
        "Falcudade de Tecnologia CENTEC (Na prática, é como se fosse estadual) (CENTEC)",
        "Faculdade Católica do Ceará (FCC)",
        "Faculdade Católica de Fortaleza  (FCF)",
        "Faculdade Católica Rainha do Sertão (FCRS)",
        "Instituto Superior de Teologia Aplicada (INTA)",
        "Instituto de Ensino Superior de Fortaleza (IESF)",
        "Centro Universitário Christus (UNICHRISTUS)",
        "Centro Universitário Fametro  (UNIFAMETRO)",
        "Universidade de Fortaleza (UNIFOR)",
        "Centro Universitário Unifanor (UNIFANOR)",
        "Centro Universitário da Grande Fortaleza (UNIGRANDE)",
        "Universidade Patativa do Assaré (UPA)",
        "Centro Universitário Católica de Quixadá (UNICATÓLICA)",
        "Faculdade Integrada do Ceará (ESTÁCIO FIC)",
        "Faculdade 7 de Setembro (FA7)",
        "Faculdades Cearenses (FAC)",
        "Faculdade Evolutivo (FACE)",
        "Faculdade de Ensino e Cultura do Ceará (FAECE)",
        "Faculdade de Fortaleza (FAFOR)",
        "Faculdade de Ciências Aplicadas Doutor Leão Sampaio (FALS)",
        "Faculdade Paraíso do Ceará (FAP)",
        "Faculdade Ari de Sá (FAS)",
        "Faculdade Ateneu (FATE)",
        "Faculdade Terra Nordeste (FATENE)",
        "Faculdade Farias Brito (FFB)",
        "Faculdade de Juazeiro do Norte (FJN)",
        "Faculdade Luciano Feijão (FLF)",
        "Faculdade de Medicina de Juazeiro do Norte (FMJ)",
        "Faculdade de Tecnologia Apoena (FTA)",
        "Faculdade Vale do Jaguaribe (FVJ)",
        "Centro Universitário Leonardo da Vinci (UNIASSELVI)",
        "Centro Universitário Fametro (Unifametro)",
        "Escola Superior de Ciências da Saúde (ESCS)",
        "Instituto Federal de Brasília (IFB)",
        "Universidade de Brasília (UnB)",
        "Escola Nacional de Administração Pública (ENAP)",
        "Universidade Católica de Brasília (UCB)",
        "União Pioneira de Integração Social (UPIS)",
        "Serviço Nacional de Aprendizagem Industrial (SENAI)",
        "Centro Universitário de Brasília (CEUB)",
        "Faculdade Ateneu (FATE)",
        "Faculdade de Ciência e Tecnologia do Planalto Central (FACIPLAC)",
        "Faculdade de Ciências Sociais e Tecnológicas (FACITEC)",
        "FACULDADES INTEGRADAS ICESP Brasília (FACICESP/UNICESP)",
        "Faculdade Serrana (FASEP)",
        "Faculdades Integradas da Terra de Brasília Descredenciada (Despacho nº 59 SESu/Mec, de 2 de maio de 2011) (FTB)",
        "Instituto de Educação Superior de Brasília (IESB)",
        "Faculdade Senac-Distrito Federal (SENAC)",
        "Centro Universitário do Distrito Federal (UDF)",
        "Centro Universitário Euroamericano (UNIEURO)",
        "Centro Universitário de Desenvolvimento do Centro Oeste (UNIDESC)",
        "Universidade Paulista (UNIP)",
        "Centro Universitário Planalto do Distrito Federal (UNIPLAN)",
        "Centro Universitário Estácio UniSEB-Brasília (UNISEB)",
        "Universidade Norte do Paraná (UNOPAR)",
        "Faculdade Projeção (FAPRO)",
        "Faculdade de Ensino Superior de Linhares (FACELI)",
        "Faculdade De Musica do Espírito Santo Mauricio de Oliveira (FAMES)",
        "Instituto Federal do Espírito Santo (IFES)",
        "Universidade Federal do Espírito Santo (UFES)",
        "Escola Superior de Ciências da Santa Casa de Misericórdia de Vitória (EMESCAM)",
        "Instituto de Pesquisa em Ciências da Saude (FAPECS)",
        "Universidade Vila Velha (UVV)",
        "Centro Universitário do Espírito Santo (UNESC)",
        "Faculdades Integradas Espírito-Santenses (FAESA)",
        "Faculdade do Centro Leste (UCL)",
        "Faculdade do Espírito Santo (Favi/FACES)",
        "Escola Superior São Francisco de Assis (ESFA)",
        "Centro Universitário São Camilo (CUSC)",
        "Faculdade de Filosofia Ciências e Letras de Alegre (FAFIA)",
        "Faculdade Castelo Branco (FCB)",
        "Escola de Ensino Superior Anísio Teixeira (CESAT)",
        "Centro de Ensino Superior de Vitória (CESV)",
        "Rede de Ensino Doctum (DOCTUM)",
        "Faculdade Batista de Vitoria (FABAVI)",
        "Faculdade de Aracruz (FAACZ)",
        "Faculdade Casa do Estudante (FACE)",
        "Faculdade do Espírito Santo (FACES)",
        "Faculdade da Serra (FASE)",
        "Associação Vitoriana de Ensino Superior (FAVI)",
        "Faculdade Candido Mendes de Vitória (FCMV)",
        "Faculdades Integradas de Vitória (FDV)",
        "FUCAPE Business School (FUCAPE)",
        "Faculdade de Estudos Sociais do Espírito Santo (PIO XII)",
        "Faculdade Saberes (SABERES)",
        "Universidade Estácio de Sá (UNESA)",
        "Universidade de Rio Verde (UniRV (FESURV))",
        "Universidade Estadual de Goiás (UEG)",
        "Instituto Federal de Goiás (IFG)",
        "Universidade Federal de Goiás (UFG)",
        "Instituto Federal Goiano (IF Goiano)",
        "Universidade Federal de Catalão (UFCat)",
        "Universidade Federal de Jataí (UFJ)",
        "Instituto de Pós-Graduação (IPOG)",
        "Pontifícia Universidade Católica de Goiás (PUC)",
        "Universidade Salgado de Oliveira (UNIVERSO)",
        "Faculdade Católica de Anápolis (Fac)",
        "Universidade Luterana do Brasil (ULBRA)",
        "Faculdade do Sudeste Goiano (Fasug)",
        "Faculdade Alves Faria (ALFA)",
        "Centro de Ensino Superior de Catalão  (CESUC)",
        "Faculdade de Ceres (FACERES)",
        "Faculdade de Ciências de Rubiataba (FACER)",
        "Faculdade Evangelica de Goianesia (FACEG)",
        "Faculdade de Inhumas (Facmais)",
        "Faculdade Anhanguera de Anápolis (FAA)",
        "Faculdade Sul-Americana (FASAM)",
        "Instituto de Ensino Superior de Rio Verde (IESRIVER)",
        "Instituto Jordão de Arruda (INSJAR)",
        "Universidade Estácio de Sá (UNESA)",
        "Universidade Paulista (UNIP)",
        "Faculdade Santa Rita de Cássia (UNIFASC)",
        "Universidade Evangélica (UniEvangelica)",
        "Universidade Anhanguera (Unianhanguera)",
        "Faculdades e Colégio Aphonsiano (IAESUP)",
        "Instituto Unificado de Ensino Superior Objetivo (IUESO)",
        "Faculdade Montes Belos (FMB)",
        "Universidade Estadual do Maranhão (UEMA)",
        "Universidade Estadual da Região Tocantina do Maranhão (UEMASUL)",
        "Instituto Estadual do Maranhão (IEMA)",
        "Instituto Federal do Maranhão (IFMA)",
        "Universidade Federal do Maranhão (UFMA)",
        "Faculdade Santa Terezinha (São Luís) (CEST)",
        "Faculdade do Estado do Maranhão (São Luís) (FACEM)",
        "Faculdade de Ciências e Tecnologia do Maranhão (Caxias) (UNIFACEMA)",
        "Faculdade de Imperatriz (FACIMP)",
        "Faculdade Atenas Maranhense (FAMA)",
        "Faculdade do Baixo Paranaíba (Chapadinha) (FAP)",
        "Faculdade de Educação Santa Terezinha (Imperatriz) (FEST)",
        "Faculdade São Luís (FSL)",
        "Instituto de Ensino Superior Franciscano (Paço do Lumiar) (IESF)",
        "Instituto Maranhense de Ensino e Cultura (São Luís) (IMEC)",
        "Centro Universitário Dom Bosco (São Luís) (UNDB)",
        "Universidade CEUMA (São Luís) (CEUMA)",
        "Universidade do Estado de Mato Grosso (UNEMAT)",
        "Instituto Federal do Mato Grosso (IFMT)",
        "Universidade Federal de Mato Grosso (UFMT)",
        "Instituto Parecis de Ensino, Desenvolvimento e Pesquisa (IPE)",
        "Instituto Universitário da Maia (ISMAI/IUM)",
        "Faculdade Cuiabá (FAUC)",
        "Faculdade Cândido Rondon (FCR)",
        "Faculdade Desembargador Sávio Brandão (FAUSB)",
        "Centro Universitário Cathedral (UNICATHEDRAL)",
        "Faculdade de Ciências Sociais Aplicadas do Vale do São Lourenço (EDUVALE)",
        "Faculdade de Direito de Alta Floresta (FADAF)",
        "Fundação de Integração Social Parecis (FISP)",
        "Faculdade do Pantanal (FAPAN)",
        "Faculdade FASIPE (FASIPE)",
        "Instituto Cuiabá de Ensino e Cultura (ICEC)",
        "Centro Universitário La Salle (LA SALLE)",
        "Universidade Luterana do Brasil EAD (ULBRA)",
        "Universidade de Cuiabá (UNIC)",
        "Universidade Paulista (UNIP)",
        "Centro Universitário de Várzea Grande (UNIVAG)",
        "Universidades Unidas do Vale do Araguaia-Barra do Garças (UNIVAR)",
        "Universidade Estadual do Mato Grosso do Sul (UEMS)",
        "Universidade Federal da Grande Dourados (UFGD)",
        "Instituto Federal de Mato Grosso do Sul (IFMS)",
        "Universidade Federal do Mato Grosso do Sul (UFMS)",
        "Universidade Católica Dom Bosco (UCDB)",
        "Faculdades Integradas de Três Lagoas (AEMS)",
        "Faculdade Campo Grande (FCG)",
        "Faculdade Mato Grosso do Sul (FACSUL)",
        "Centro Universitário de Campo Grande (UNAES)",
        "Universidade para o Desenvolvimento do Estado e Região do Pantanal (UNIDERP)",
        "Centro Universitário da Grande Dourados (UNIGRAN)",
        "Fundação João Pinheiro (FJP)",
        "Universidade do Estado de Minas Gerais (UEMG)",
        "Universidade Estadual de Montes Claros (UNIMONTES)",
        "Centro Federal de Educação Tecnológica de Minas Gerais (CEFET MG)",
        "Instituto Federal de Minas Gerais (IFMG)",
        "Instituto Federal do Norte de Minas Gerais (IFNMG)",
        "Instituto Federal do Sudeste de Minas (IFSM)",
        "Instituto Federal do Sul de Minas (IFSULDEMINAS)",
        "Instituto Federal do Triângulo Mineiro (IFTM)",
        "Universidade Federal de Juiz de Fora (UFJF)",
        "Universidade Federal de Lavras (UFLA)",
        "Universidade Federal de Minas Gerais (UFMG)",
        "Universidade Federal de Ouro Preto (UFOP)",
        "Universidade Federal de São João del-Rei (UFSJ)",
        "Universidade Federal do Triângulo Mineiro (UFTM)",
        "Universidade Federal de Uberlândia (UFU)",
        "Universidade Federal de Viçosa (UFV)",
        "Universidade Federal dos Vales do Jequitinhonha e Mucuri (UFVJM)",
        "Universidade Federal de Alfenas (UNIFAL)",
        "Universidade Federal de Itajubá (UNIFEI)",
        "Academia de Polícia Militar de Minas Gerais (APM MG)",
        "Escola de Engenharia Kennedy (EEK)",
        "Escola de Enfermagem Wenceslau Braz (EEWB)",
        "Escola Superior Dom Helder Câmara (ESDHC)",
        "Faculdade Católica de Pouso Alegre (FACAPA)",
        "Faculdade São Francisco Xavier  (FACSFX)",
        "Faculdade de Direito de Ipatinga  (FADIPA)",
        "Faculdade Arquidiocesana de Curvelo  (FAC)",
        "Faculdade Arquidiocesana de Pirapora  (FAP)",
        "Faculdades Integradas Adventistas de Minas Gerais (FADMINAS)",
        "Faculdade Presbiteriana Gammon (FAGAMMON)",
        "Faculdade de Direito do Sul de Minas (FDSM)",
        "Faculdade de Tecnologia Alto e Médio São Francisco  (FAC FUNAM)",
        "Faculdade Cenecista de Varginha (FACECA)",
        "Faculdade de Filosofia, Ciências e Letras do Alto São Francisco (FASF/Unisa)",
        "Faculdades Associadas de Uberaba (FAZU)",
        "Faculdade de Ciências Médicas de Minas Gerais (FCMMG)",
        "Faculdade de Ciências Médicas e da Saúde de Juiz de Fora (FCMS/JF)",
        "Faculdade Metodista Granbery (FMG)",
        "Faculdade de Medicina de Itajubá (FMIT)",
        "Faculdade de Medicina de Barbacena (FUNJOB)",
        "Faculdade Machado Sobrinho (FMS)",
        "Fundação Mineira de Educação e Cultura (FUMEC)",
        "Fundação Comunitária de Ensino Superior de Itabira (FUNCESI)",
        "Instituto Nacional de Telecomunicações (INATEL)",
        "Instituto de Educação Superior de Patrocínio (IESP)",
        "Pontifícia Universidade Católica de Minas Gerais (PUCMG)",
        "Universidade de Itaúna (UIT)",
        "Centro Universitário do Planalto de Araxá (UNIARAXÁ)",
        "Centro Universitário de Belo Horizonte (UNIBH)",
        "Centro Universitário do Cerrado-Patrocínio (UNICERP)",
        "Centro Universitário de Sete Lagoas Monsenhor Messias (UNIFEMM)",
        "Centro Universitário de Formiga (UNIFORMG)",
        "Centro Universitário Católica do Leste de Minas Gerais (UNILESTE)",
        "Universidade Presidente Antônio Carlos (UNIPAC)",
        "Centro Universitário de Patos de Minas (UNIPAM)",
        "Centro Universitário do Triângulo (UNITRI)",
        "Universidade de Uberaba (UNIUBE)",
        "Universidade do Vale do Sapucaí (UNIVAS)",
        "Universidade Vale do Rio Doce (UNIVALE)",
        "Universidade Vale do Rio Verde (UNINCOR)",
        "Universidade Salgado de Oliveira (UNIVERSO)",
        "Centro Universitário Eaprenda Elearning (CUEA)",
        "Instituto Metodista Izabela Hendrix (IMIH)",
        "Associação Sul Mineira de Educação e Cultura (ASMEC)",
        "Centro Educacional Conceição Nunes (CECON)",
        "Centro de Ensino Superior de Itabira (CENSI)",
        "Centro Universitário Estácio Juiz de Fora (ESTÁCIO JUIZ DE FORA)",
        "Rede de Ensino Doctum (DOCTUM)",
        "Faculdade de Ensino de Minas Gerais (FACEMG)",
        "Faculdade de Ciências Sociais Aplicadas do Sul de Minas (FACESM)",
        "Faculdade de Ciências da Saúde de Campos Gerais (FACICA)",
        "Faculdade de Ciências Contabeis de Divinópolis (FACED)",
        "Faculdade de Ciências Humanas de Curvelo  (FACIC)",
        "Faculdade do Sudeste Mineiro (FACSUM)",
        "Faculdade de Ciências e Tecnologia de Unaí (FACTU)",
        "Faculdade de Frutal  (FAF)",
        "Faculdade Arnaldo Jessen  (FAJESSEN)",
        "Instituto Superior de Educação Verde Norte (FAVENORTE)",
        "Faculdade de Engenharia de Minas Gerais (FEMG)",
        "Faculdade Juiz de Fora (FJF)",
        "Faculdades Milton Campos (FMC)",
        "Faculdade Pitágoras de Administração Superior (FPAS)",
        "Faculdade Promove de Minas Gerais (FPMG)",
        "Faculdade Politécnica de Uberlândia (FPU)",
        "Faculdades Santo Agostinho (FSA)",
        "Faculdades Unificadas de Cataguases  (FUC)",
        "Fundação Educacional de Divinópolis (FUNEDI)",
        "Instituto Belo Horizonte de Ensino Superior (IBHES)",
        "Instituto de Ensino Superior Cenecista (INESC)",
        "Instituto de Ensino Superior Presidente Tancredo Neves (IPTAN)",
        "Instituto Superior de Educação de Campos Gerais (ISEC)",
        "Faculdade Santa Amélia (SECAL)",
        "Centro Universitário UNA (UNA)",
        "Centro Universitário Newton Paiva (NEWTON)",
        "Universidade de Alfenas José do Rosário Vellano (UNIFENAS)",
        "Centro Universitário de Lavras (UNILAVRAS)",
        "Centro Universitário de Itajubá (UNIVERSITAS)",
        "União de Ensino Superior de Viçosa (UNIVIÇOSA)",
        "Centro Universitário de Caratinga (UNEC)",
        "Universidade Federal Rural da Amazônia (UFRA)",
        "Universidade Federal do Pará (UFPA)",
        "Instituto Federal do Pará (IFPA)",
        "Universidade Federal do Oeste do Pará (UFOPA)",
        "Universidade Federal do Sul e Sudeste do Pará (UNIFESSPA)",
        "Universidade do Estado do Pará (UEPA)",
        "Centro de Instrução Almirante Braz de Aguiar (CIABA)",
        "Centro de Educação da Amazônia (CEAMA)",
        "Centro Universitário do Pará (CESUPA)",
        "Escola Superior Madre Celeste (ESMAC)",
        "Escola Superior da Amazônia (ESAMAZ)",
        "Estácio de Sá Faculdade do Pará (ESTÁCIO FAP)",
        "Faculdade Adventista da Amazônia (FAAMA)",
        "Faculdade da Amazônia (FAAM)",
        "Faculdade Metropolitana da Amazônia (FAMAZ)",
        "Faculdade de Belém (FABEL)",
        "Faculdade de Ensino Tecnológico do Estado do Pará (FACETE)",
        "Faculdade Ideal (FACI)",
        "Faculdade Ipiranga (FACIP)",
        "Faculdade Pan Amazônica (FAPAN)",
        "Faculdade Paraense de Ensino (FAPEN)",
        "Faculdade de Castanhal (FCAT)",
        "Faculdade de Estudos Avançados do Pará (FEAPA)",
        "Faculdades Integradas do Tapajós (Santarém) (FIT)",
        "Faculdade Integrada Brasil Amazônia (FIBRA)",
        "Faculdade Metropolitana de Marabá (FMM)",
        "Faculdade Pan Americana (FPA)",
        "Instituto de Ensinos Superiores da Amazônia (IESAM)",
        "Universidade da Amazônia (UNAMA)",
        "Universidade Paulista (UNIP)",
        "Universidade Estadual da Paraíba (UEPB)",
        "Instituto Federal da Paraíba (IFPB)",
        "Universidade Federal da Paraíba (UFPB)",
        "Universidade Federal de Campina Grande (UFCG)",
        "Centro Universitário de João Pessoa (UNIPÊ)",
        "Centro Universitário de União da Vitória (Uniuv)",
        "Associação Paraibana de Ensino Renovado (ASPER)",
        "DeVry João Pessoa (DEVRY)",
        "Escola Superior de Aviação Civil (ESAC)",
        "Faculdade Maurício de Nassau (ESBJ)",
        "Faculdade de Campina Grande (FAC)",
        "Faculdade de Enfermagem Nova Esperança (FACENE)",
        "Centro Universitário Autônomo do Brasil (UNIBRASIL)",
        "Faculdade de Filosofía, Ciências e Letras de Cajazeiras (FAFIC)",
        "Faculdade de Filosofía Ciências e Letras (FAFIL)",
        "Faculdade Paraibana (FAP)",
        "Faculdade Reinaldo Ramos (FARR)",
        "Faculdade Santa Emília de Rodat (FASER)",
        "Faculdade São Francisco da Paraíba (FASP)",
        "Faculdade de Tecnologia de João Pessoa (FATEC)",
        "Faculdade de Tecnología da Paraíba (FATECPB)",
        "Faculdades Vale do Piancó (FAVAP)",
        "Faculdade de Ciências Médicas de Campina Grande (FCM)",
        "Faculdade de Ciências Médicas da Paraíba (FCM PB)",
        "Faculdade Evilásio Formiga (FEF)",
        "Faculdade de Enfermagem São Vicente de Paula (FESVIP)",
        "Faculdade de Ensino Superior da Paraíba (FESP)",
        "Faculdades Integradas Anglo-Americano (FIAA)",
        "Faculdade Potiguar da Paraíba (FPB)",
        "Faculdade Internacional da Paraíba (FPB)",
        "Faculdade Paraibana de Processamento de Dados (FPPD)",
        "Faculdade Santa María (FSM)",
        "Instituto de Educação Superior da Paraíba (IESP)",
        "Insituto Paraibano de Ensino Renovado (INPER)",
        "Instituto de Pós-Graduação & Graduação (IPOG)",
        "Instituto Superior de Educação de Cajazeiras (ISEC)",
        "Faculdade de Ciências Contábeis Luiz Mendes (LUMEN FAC)",
        "Faculdade de Tecnologia Ibratec (UNIBRATEC)",
        "Centro Universitário de Patos (UNIFIP)",
        "Faculdade Unida da Paraíba (UNIPB)",
        "Faculdade de Tecnologia do Uniuol (UNIUOL)",
        "Centro Universitário Facisa (UNIFACISA)",
        "Universidade Estadual do Paraná (UNESPAR)",
        "Universidade Estadual de Londrina (UEL)",
        "Universidade Estadual de Maringá (UEM)",
        "Universidade Estadual do Norte do Paraná (UENP)",
        "Universidade Estadual de Ponta Grossa (UEPG)",
        "Universidade Estadual do Centro-Oeste (UNICENTRO)",
        "Universidade Estadual do Oeste do Paraná (UNIOESTE)",
        "Instituto Federal do Paraná (IFPR)",
        "Universidade Tecnológica Federal do Paraná (UTFPR)",
        "Universidade Federal do Paraná (UFPR)",
        "Universidade Federal da Integração Latino-Americana (UNILA)",
        "Universidade Federal da Fronteira do Sul (UFFS)",
        "FAE Centro Universitário (FAE)",
        "Fundação Assis Gurgacz (FAG)",
        "Faculdade Evangélica do Paraná (FEPAR)",
        "Faculdades Pequeno Príncipe (FPP)",
        "Faculdade Teológica Sul Americana (FTSA)",
        "Pontifícia Universidade Católica do Paraná (PUCPR)",
        "Centro Universitário Filadélfia (UNIFIL)",
        "União Educacional de Cascavel (Univel)",
        "Faculdade Ingá (UNINGÁ)",
        "Faculdade Campo Real (CAMPO REAL)",
        "Centro de Ensino Superior dos Campos Gerais (CESCAGE)",
        "Centro de Ensino Superior de Foz do Iguaçu (CESUFOZ)",
        "Faculdade Alfa Brasil (FAAB)",
        "Faculdade Curitibana (FAC)",
        "Faculdade de Foz do Iguaçu (FAFIG)",
        "Faculdade Guairacá (FAG)",
        "Faculdade Jaguariaíva (FAJAR)",
        "Faculdade de Telêmaco Borba (FATEB)",
        "Faculdade do Noroeste Paranaense (FANP)",
        "Faculdade Paranaense (FAPAR)",
        "Faculdades de Pinhais (FAPI)",
        "Faculdade Sagrada Família (FASF)",
        "Faculdades Integradas Santa Cruz de Curitiba (FARESC)",
        "Faculdade de Engenharia e Inovação Técnico Profissional (FEITEP)",
        "Fundação de Ensino Superior de Clevelândia (FESC)",
        "Faculdade Guarapuava (FG)",
        "Faculdades Integradas Espírita (FIES)",
        "Faculdade Metropolitana Londrinense (FML)",
        "Organização Paranaense de Ensino Técnico (OPET)",
        "Faculdade Santa Amélia (SECAL)",
        "Centro Universitário União Dinâmica das Cataratas (UDC)",
        "Universidade Eletrônica do Brasil (UEB)",
        "Centro Universitário Campos de Andrade (UNIANDRADE)",
        "Faculdade União de Campo Mourão (UNICAMPO)",
        "Centro Universitário Integrado de Campo Mourão (INTEGRADO)",
        "Centro Universitário de Maringá (UniCesumar)",
        "Centro Universitário Curitiba (UNICURITIBA)",
        "Grupo Educacional Uninter (UNINTER)",
        "Universidade Paranaense (UNIPAR)",
        "Universidade do Norte do Paraná (UNOPAR)",
        "Faculdades do Centro do Paraná (UCP)",
        "Universidade Positivo (UP)",
        "Universidade Tuiuti do Paraná (UTP)",
        "União de Ensino do Sudoeste do Paraná  (UNISEP)",
        "Universidade Federal do Piauí (UFPI)",
        "Instituto Federal do Piauí (IFPI)",
        "Universidade Federal do Delta do Parnaíba (UFPDra)",
        "Universidade Estadual do Piauí (UESPI)",
        "Instituto de Educação Antonino Freire (ISEAF)",
        "Instituto Católico de Estudos de Ensino Superior do Piauí  (ICESPI)",
        "Centro Universitário Uninovafapi (UNINOVAFAPI)",
        "Centro Universitário Santo Agostinho (UNIFSA)",
        "Centro Universitário do Piauí (Aespi/Fapi) (UNIFAPI)",
        "Centro Universitário Maurício de Nassau (UNINASSAU)",
        "Faculdade Integral Diferencial (UNIFACID)",
        "Centro de Ensino Superior Vale do Parnaíba (CESVALE)",
        "Centro de Ensino Unificado de Teresina (CEUT)",
        "Instituto Camilo Filho (ICF)",
        "Centro de Educação Tecnológico (CET)",
        "Instituto Educacional Raimundo Sá (Faculdade Raimundo Sá) (IERSA)",
        "Faculdade das atividades empresariais de Teresina (FAETE)",
        "Faculdade Santa Teresa D'avilla (FAST)",
        "Christus Faculdade do Piauí (CHISFAPI)",
        "Faculdade do Cerrado Piauiense (FCPI)",
        "Faculdade Professor Miguel Ângelo da Silva Santos Macaé (FeMASS)",
        "Instituto Superior de Educação do Rio de Janeiro (ISERJ)",
        "Faculdade de Educação Tecnológica do Estado do Rio de Janeiro (FAETERJ)",
        "Universidade Estadual do Norte Fluminense (UENF)",
        "Universidade do Estado do Rio de Janeiro (UERJ)",
        "Universidade Estadual da Zona Oeste (UEZO)",
        "Instituto de Matemática Pura e Aplicada (IMPA)",
        "Centro Brasileiro de Pesquisas Físicas (CBPF)",
        "Centro Federal de Educação Tecnológica Celso Suckow da Fonseca (CEFET/RJ)",
        "Escola Nacional de Ciências Estatísticas (ENCE)",
        "Instituto Federal Fluminense (IFF)",
        "Instituto Federal do Rio de Janeiro (IFRJ)",
        "Universidade Federal Fluminense (UFF)",
        "Universidade Federal do Rio de Janeiro (UFRJ)",
        "Universidade Federal Rural do Rio de Janeiro (UFRRJ)",
        "Universidade Federal do Estado do Rio de Janeiro (UNIRIO)",
        "Academia de Bombeiro Militar Dom Pedro II (ABMDP II)",
        "Academia Militar de Agulhas Negras (AMAN)",
        "Academia de Polícia Militar Dom João VI (APMDJVI)",
        "Centro de Instrução Almirante Graça Aranha (CIAGA)",
        "Escola Naval (EN)",
        "Instituto Militar de Engenharia (IME)",
        "Faculdade Presbiteriana Mackenzie Rio (MACKENZIE RIO)",
        "Instituto Brasileiro de Mercado e Capitais (IBMEC)",
        "Fundação Getulio Vargas (FGV)",
        "Escola Superior de Propaganda e Marketing (ESPM RIO)",
        "Pontifícia Universidade Católica do Rio de Janeiro (PUC RIO)",
        "Universidade do Grande Rio (UNIGRANRIO)",
        "Centro Universitário Celso Lisboa (CELSO LISBOA)",
        "Escola Nacional de Seguros (FUNENSEG)",
        "Faculdades Integradas de Jacarepaguá (FIJ)",
        "Faculdades Integradas Hélio Alonso (FACHA)",
        "Faculdade de Filosofia Santa Doroteia (FFSD)",
        "Faculdade de Medicina de Campos (FMC)",
        "Faculdade Salesiana de Macaé (FSMA)",
        "Fundação Técnico Educacional Souza Marques (FTESM)",
        "Faculdade de São Bento do Rio de Janeiro (FSB/RJ)",
        "Universidade Cândido Mendes (UCAM)",
        "Centro Universitário IBMR (IBMR)",
        "Universidade Castelo Branco (UCB)",
        "Universidade Católica de Petrópolis (UCP)",
        "Centro Universitário Geraldo Di Biase (UGB)",
        "Universidade Gama e Souza (UGS)",
        "Centro de Ensino Superior de Valença (UNIFAA)",
        "Centro Universitário Augusto Motta (UNISUAM)",
        "UNIABEU Centro Universitário (UNIABEU)",
        "Centro Universitário Carioca (UNICARIOCA)",
        "Centro Universitário Serra dos Órgãos (UNIFESO)",
        "Universidade Salgado de Oliveira (UNIVERSO)",
        "Universidade Severino Sombra (USS)",
        "Universidade Santa Úrsula (USU)",
        "Universidade Veiga de Almeida (UVA)",
        "Sociedade Universitária Redentor (Faculdade Redentor) (REDENTOR)",
        "Centro Universitário Universus Veritas (UNIVERITAS)",
        "Faculdade de Medicina de Petrópolis/Faculdade Arthur Sá Earp Neto (FMP/FASE)",
        "Instituto Infnet (INFNET)",
        "Faculdades Dom Bosco (AEDB)",
        "Faculdade Senac Rio de Janeiro (SENAC)",
        "Centro Universitário de Barra Mansa (UBM)",
        "Universidade Estácio de Sá (UNESA)",
        "Centro Universitário de Volta Redonda (UNIFOA)",
        "Universidade Iguaçu (UNIG)",
        "Fundação Educacional Unificada Campo Grandense (FEUC)",
        "Universidade do Estado do Rio Grande do Norte (UERN)",
        "Instituto Federal do Rio Grande do Norte (IFRN)",
        "Universidade Federal Rural do Semi-Árido (UFERSA)",
        "Universidade Federal do Rio Grande do Norte (UFRN)",
        "Universidade Luterana do Brasil (Polo Natal) (ULBRA)",
        "Universidade Potiguar (UNP)",
        "Faculdade Natalense para o Desenvolvimento do Rio Grande do Norte (FARN)",
        "Faculdade de Excelência do Rio Grande do Norte (Fatern Gama Filho)",
        "Faculdade de Natal (FAL)",
        "Faculdade de Ciências, Cultura e Extensão do RN (Facex)",
        "Faculdade Câmara Cascudo (FCC)",
        "Faculdade Natalense de Ensino e Cultura (Fanec)",
        "Faculdade Católica Nossa Senhora Das Neves (FCNSN)",
        "Universidade Norte do Paraná (Polo Natal) (UNOPAR)",
        "Faculdade Maurício de Nassau Pólo Natal (UNINASSAU)",
        "Instituto de Pós-Graduação Pólo Natal (IPOG)",
        "Faculdade Católica do Rio Grande do Norte (FCRN)",
        "Faculdade de Enfermagem e Medicina Nova Esperança de Mossoró (Facene/RN)",
        "Faculdade Mater Christi (Mater Christi)",
        "Universidade Estadual do Rio Grande do Sul (UERGS)",
        "Universidade Federal do Rio Grande (FURG)",
        "Instituto Federal Farroupilha (IFFarroupilha)",
        "Instituto Federal do Rio Grande do Sul (IFRS)",
        "Instituto Federal Sul-rio-grandense (IFSul)",
        "Universidade Federal de Ciências da Saúde de Porto Alegre (UFCSPA)",
        "Universidade Federal de Pelotas (UFPEL)",
        "Universidade Federal do Rio Grande do Sul (UFRGS)",
        "Universidade Federal de Santa Maria (UFSM)",
        "Universidade Federal do Pampa (UNIPAMPA)",
        "Universidade Federal da Fronteira do Sul (UFFS)",
        "Faculdade CNEC Santo Ângelo (CNECSAN)",
        "Faculdades de Taquara (FACCAT)",
        "Faculdade Cenecista de Osório (FACOS)",
        "Faculdade Integrada de Santa Maria (FISMA)",
        "Faculdade Porto-Alegrense (FAPA)",
        "Universidade Feevale (FEEVALE)",
        "Faculdade Cenecista Nossa Senhora dos Anjos (FACENSA)",
        "Instituto Adventista Cruzeiro do Sul (IACS)",
        "Instituto de Educação Cenecista Marquês de Herval (MARQUÊS)",
        "Centro Universitário Metodista do Instituto Porto Alegre (IPA)",
        "Pontifícia Universidade Católica do Rio Grande do Sul (PUCRS)",
        "Universidade Católica de Pelotas (UCPEL)",
        "Universidade de Caxias do Sul (UCS)",
        "Universidade Luterana do Brasil (ULBRA)",
        "Universidade de Cruz Alta (UNICRUZ)",
        "Universidade Franciscana (UFN)",
        "Universidade Regional do Noroeste do Estado do Rio Grande do Sul (UNIJUÍ)",
        "Centro Universitário Ritter dos Reis (UNIRITTER)",
        "Universidade La Salle RJ (UNILASALLE)",
        "Universidade de Santa Cruz do Sul (UNISC)",
        "Universidade do Vale do Rio dos Sinos (UNISINOS)",
        "Centro Universitário UNIVATES (UNIVATES)",
        "Universidade de Passo Fundo (UPF)",
        "Universidade da Região da Campanha (URCAMP)",
        "Universidade Regional Integrada do Alto Uruguai e das Missões (URI)",
        "Faculdades EST (EST)",
        "Centro de Ensino Superior Cenecista de Farroupilha (CESF)",
        "Escola Superior de Hotelaria Castelli (CASTELLI)",
        "Escola Superior de Música e Artes do Rio Grande do Sul (ESMARGS)",
        "Faculdade de Desenvolvimento do Rio Grande do Sul (FADERGS)",
        "Faculdades Equipe Sapucaia do Sul (FAE)",
        "Faculdade Antonio Meneghetti (FAM)",
        "Faculdade da Serra Gaúcha (FSG)",
        "Faculdade de Tecnologia TecBrasil (FTEC)",
        "Instituto de Desenvolvimento Educacional do Alto Uruguai Getúlio Vargas (IDEAU)",
        "Faculdade Senac Rio Grande do Sul (SENAC)",
        "Instituição Educacional São Judas Tadeu (SJT)",
        "Anhanguera Educacional S/A (UNIANHANGUERA)",
        "Centro Universitário Ritter dos Reis (UNIRITTER)",
        "Escola Técnica e Superior Machado de Assis Santa Rosa (FEMA)",
        "Faculdade de Direito de Santa Maria (FADISMA)",
        "Faculdade Anglo Americano de Caxias do Sul  Grupo Educacional (FAACS)",
        "Universidade Federal de Rondônia (UNIR)",
        "Instituto Federal de Rondônia (IFRO)",
        "Faculdade Católica de Rondônia  (FCR)",
        "Centro Universitário São Lucas de Porto Velho (UNIFSL)",
        "Centro Universitário São Lucas de Ji Paraná (UNIFSL)",
        "Associação Vilhenense de Educação e Cultura (AVEC)",
        "Faculdade da Amazônia (FAMA)",
        "Faculdade de Educação e Cultura de Vilhena (FAEV/UNESC)",
        "Faculdade de Ciências Humanas, Exatas e Letras de Rondônia (FARO)",
        "Faculdade Interamericana de Porto Velho (UNIRON)",
        "Centro Universitário Luterano de Ji Paraná (CEULJI/ULBRA)",
        "Faculdades Integradas Aparício Carvalho (FIMCA)",
        "Faculdades Integradas de Ariquemes (FIAR)",
        "Faculdades Associadas de Ariquemes (FAAR)",
        "Instituto Luterano de Ensino Superior de Porto Velho (ILES/ULBRA)",
        "Faculdade de Educação e Meio Ambiente (FAEMA)",
        "Faculdade de Ciências Biomédicas de Cacoal (FACIMED)",
        "Faculdades Integradas de Cacoal (UNESC)",
        "Faculdade Panamericana de Ji Paraná (UNIJIPA)",
        "Universidade Estadual de Roraima (UERR)",
        "Universidade Federal de Roraima (UFRR)",
        "Instituto Federal de Roraima (IFRR)",
        "Estácio Atual (Estácio)",
        "Faculdade de Ciências, Educação e Teologia do Norte do Brasil (FACETEN)",
        "Faculdades Cathedral (Cathedral)",
        "Faculdade Roraimense de Ensino Superior (FARES)",
        "constituídas sob a forma de Fundações (Municipais)",
        "Fundação Universidade Regional de Blumenau (FURB)",
        "Sociedade Educacional de Santa Catarina (SOCIESC)",
        "Universidade do Extremo Sul Catarinense (UNESC)",
        "Universidade Municipal de São José (USJ)",
        "Faculdade Municipal de Palhoça (FMP)",
        "Centro Estadual de Educação Tecnológica Paula Souza (CEETEPS)",
        "Universidade do Estado de Santa Catarina (UDESC)",
        "Universidade Federal da Fronteira Sul (UFFS)",
        "Instituto Federal de Santa Catarina (IFSC)",
        "Instituto Federal Catarinense (IFC)",
        "Universidade Federal de Santa Catarina (UFSC)",
        "Associação Catarinense de Ensino (ACE)",
        "Faculdade Concórdia (FACC)",
        "Faculdade Santa Rita de Chapecó (FAACH)",
        "Faculdade Santa Rita Palmitos (FAP)",
        "Faculdade Senac SC (SENAC)",
        "Universidade do Oeste de Santa Catarina (UNOESC)",
        "Centro Universitário Leonardo da Vinci (UNIASSELVI)",
        "Universidade Barriga Verde (UNIBAVE)",
        "Centro Universitário de Brusque  (UNIFEBE)",
        "Universidade do Sul de Santa Catarina (UNISUL)",
        "Universidade do Contestado (UNC)",
        "Universidade Alto Vale do Rio do Peixe (UNIARP)",
        "Centro Universitário de Jaraguá do Sul (UNERJ)",
        "Universidade do Vale do Itajaí (UNIVALI)",
        "Universidade da Região de Joinville (UNIVILLE)",
        "Universidade Comunitária Regional de Chapecó (UNOCHAPECÓ)",
        "Universidade do Planalto Catarinense (UNIPLAC)",
        "Universidade do Sul de Santa Catarina (UNISUL)",
        "Centro Universitário Católica de Santa Catarina (Católica SC)",
        "Centro Universitário Avantis (UNIAVAN)",
        "Complexo de Ensino Superior de Santa Catarina (Cesusc)",
        "Universidade de Taubaté (UNITAU)",
        "Universidade Municipal de São Caetano do Sul (USCS)",
        "Centro Universitário Municipal de Franca (FACEF)",
        "Centro Universitário das Faculdades Associadas de Ensino (UNIFAE)",
        "Centro Universitário de Franca (Uni FACEF)",
        "Centro Universitário Fundação Santo André (CUFSA)",
        "Faculdade de Campo Limpo Paulista (FACCAMP)",
        "Escola de Engenharia de Piracicaba (EEP/FUMEP)",
        "Escola Superior de Cruzeiro Prefeito Hamilton Vieira Mendes (ESEFIC)",
        "Escola Superior de Educação Física de Jundiaí (ESEFJ)",
        "Fundação Instituto Tecnológico de Osasco (FITO)",
        "Faculdade de Ciências e Tecnologia de Birigui (FATEB)",
        "Faculdade de Direito de Franca (FDF)",
        "Faculdade de Direito de São Bernardo do Campo (FDSBC)",
        "Faculdade de Filosofia, Ciências e Letras de Ibitinga (FAIBI)",
        "Faculdade de Filosofia Ciências e Letras de São José do Rio Pardo (FFCL)",
        "Faculdade de Medicina do ABC (FMABC)",
        "Faculdade de Medicina de Jundiaí (FMJ)",
        "Faculdade Municipal Professor Franco Montoro de Mogi Guaçu (FMPFM)",
        "Faculdades Adamantinenses Integradas (FAI)",
        "Faculdades da Fundação de Ensino de Mococa (FAFEM)",
        "Faculdades Integradas de Santa Fé do Sul (FUNEC)",
        "Instituto de Ensino Superior da Fupesp (IESF)",
        "Instituto Matonense Municipal de Ensino Superior (IMMES)",
        "Instituto Municipal de Ensino Superior de Bebedouro Victório Cardassi (IMESB)",
        "Instituto Municipal de Ensino Superior de Catanduva (IMES Catanduva)",
        "Instituto Municipal de Ensino Superior de São Manuel (IMESSM)",
        "Instituto Municipal de Ensino Superior de Assis (IMESA)",
        "Instituto Paulista de Ensino e Pesquisa (IPEP)",
        "Fundação Educacional Araçatuba (FEA)",
        "Universidade de São Paulo (USP)",
        "Universidade Estadual de Campinas (UNICAMP)",
        "Universidade Estadual Paulista (UNESP)",
        "Universidade Virtual do Estado de São Paulo (UNIVESP)",
        "Faculdade de Medicina de Marília (FAMEMA)",
        "Faculdade de Medicina de São José do Rio Preto (FAMERP)",
        "Faculdade de Tecnologia do Estado de São Paulo (FATEC)",
        "Academia de Polícia Militar do Barro Branco (APMBB)",
        "Campus de Santo André da Universidade Federal do ABC (UFABC)",
        "Universidade Federal de São Carlos (UFSCar)",
        "Universidade Federal de São Paulo (UNIFESP)",
        "Universidade Federal do ABC (UFABC)",
        "Instituto Nacional de Pesquisas Espaciais (INPE)",
        "Instituto Federal de Educação, Ciência e Tecnologia de São Paulo (IFSP)",
        "Instituto Tecnológico de Aeronáutica (ITA)",
        "Academia da Força Aérea (AFA)",
        "Pontifícia Universidade Católica de São Paulo (PUC SP)",
        "Pontifícia Universidade Católica de Campinas (PUC Campinas)",
        "Universidade Católica de Santos (UNISANTOS)",
        "Universidade de Santo Amaro (UNISA)",
        "Universidade do Vale do Paraíba (UNIVAP)",
        "Universidade do Oeste Paulista (UNOESTE)",
        "Universidade São Francisco (USF)",
        "Universidade Sant'Anna (UNISANT'ANNA)",
        "Universidade Metodista de São Paulo (UMESP)",
        "Universidade de Ribeirão Preto (UNAERP)",
        "Universidade Presbiteriana Mackenzie (MACK)",
        "Universidade Metodista de Piracicaba (UNIMEP)",
        "Universidade Camilo Castelo Branco (UNICASTELO)",
        "Universidade do Sagrado Coração (UNISAGRADO)",
        "Centro Universitário Salesiano de São Paulo (UNISAL)",
        "Universidade Cidade de São Paulo (UNICID)",
        "Centro Universitário da Fundação Hermínio Ometto (UNIARARAS)",
        "Centro Universitário Barão de Mauá (CBM)",
        "Centro Universitário Católico Salesiano Auxilium (UNISALESIANO)",
        "Centro Universitário São Camilo (CUSC)",
        "Centro Universitário Belas Artes de São Paulo (FEBASP)",
        "Centro Universitário FEI (FEI)",
        "Centro Universitário de Votuporanga (UNIFEV)",
        "Centro Universitário das Faculdades Metropolitanas Unidas (FMU)",
        "Centro Universitário Senac (SENAC)",
        "Centro Universitário Adventista de São Paulo (UNASP)",
        "Universidade de Araraquara (UNIARA)",
        "Centro Universitário Central Paulista (UNICEP)",
        "Centro Universitário Assunção (UNIFAI)",
        "Centro Universitário da Fundação Educacional de Barretos (UNIFEB)",
        "Centro Universitário Octávio Bastos (UNIFEOB)",
        "Centro Universitário FIEO (UNIFIEO)",
        "União das Faculdades dos Grandes Lagos (UNILAGO)",
        "Centro Universitário Monte Serrat (UNIMONTE)",
        "Centro Universitário do Norte Paulista (UNORP)",
        "Centro Universitário de Rio Preto (UNIRP)",
        "Centro Universitário Eurípedes de Marília (UNIVEM)",
        "Centro Universitário Toledo (UNITOLEDO)",
        "A.C.Camargo Cancer Center (ACCC)",
        "Faculdade Canção Nova  (FCN)",
        "Business School São Paulo (BSP)",
        "Fundação Getúlio Vargas (FGV)",
        "Faculdade de Teologia e Ciências  (FATEC)",
        "Fundação Escola de Comércio Álvares Penteado (FECAP)",
        "Escola Superior de Propaganda e Marketing (ESPM)",
        "Escola Superior de Administração e Gestão (ESAGS)",
        "Instituto Mauá de Tecnologia (IMT)",
        "Faculdade Cásper Líbero (FCL)",
        "Fundação Armando Álvares Penteado (FAAP)",
        "Instituto de Ensino e Pesquisa (Insper)",
        "Instituto Mauá de Tecnologia (IMT)",
        "Fundação Escola de Sociologia e Política de São Paulo (FESPSP)",
        "Faculdades Integradas Rio Branco (FRB)",
        "Faculdades Integradas Dom Pedro II (DOMPEDRO)",
        "Faculdades Integradas de Ourinhos (FIO)",
        "Faculdades Integradas Rio Branco (FIRB)",
        "Faculdade de Teologia Metodista Livre (FTML)",
        "Faculdade de Tecnologia Termomecânica (FTT)",
        "Faculdade Paulus de Comunicação e Tecnologia (FAPCOM)",
        "Faculdade União Cultural do Estado de São Paulo (UCESP)",
        "Instituto São Paulo de Formação e Especialização Profissional (UniSãopaulo)",
        "Faculdade Zumbi dos Palmares (FAZP)",
        "Fundação Educacional de Fernandópolis (FEF)",
        "Faculdade de Ciências Médicas da Santa Casa de São Paulo (FCMSCSP)",
        "Faculdade Dehoniana (FD)",
        "Fundação Dom Cabral (FDC)",
        "Faculdade Santa Marcelina (FASM)",
        "Universidade Nove de Julho (UNINOVE Campus Memorial)",
        "Universidade Paulista (UNIP Campus Paraíso)",
        "Universidade Brasil (ICESP)",
        "Centro Universitário Nossa Senhora do Patrocínio (CEUNSP)",
        "Faculdade Drummond (FCDA)",
        "Centro Universitário Sumaré (ISES)",
        "Centro Universitário de Bauru (ITE)",
        "Universidade Anhembi Morumbi (UAM)",
        "Universidade Braz Cubas (UBC)",
        "Universidade de Mogi das Cruzes (UMC)",
        "Universidade Guarulhos (UNG)",
        "Universidade do Grande ABC (UniABC)",
        "Universidade Bandeirante de São Paulo (UNIBAN)",
        "Centro Universitário Anhanguera de São Paulo (UNIBERO)",
        "Universidade Cruzeiro do Sul (UNICSUL)",
        "Centro Universitário Estácio UniSEB -São Paulo (UNISEB)",
        "Universidade de Franca (UNIFRAN)",
        "Universidade de Marília (UNIMAR)",
        "Universidade Nove de Julho (UNINOVE)",
        "Universidade Paulista (UNIP)",
        "Universidade Metropolitana de Santos (UNIMES)",
        "Universidade Santa Cecília (UNISANTA)",
        "Universidade de Sorocaba (UNISO)",
        "Universidade São Judas Tadeu (USJT)",
        "Anhanguera Educacional (AESA)",
        "Faculdades de Campinas (FACAMP)",
        "Faculdade de Ciências Humanas de Cruzeiro (FACIC)",
        "Faculdade de Administração de São José do Rio Preto (FACMIL)",
        "Faculdade de Direito de Sorocaba (FADI)",
        "Faculdades Integradas de São Carlos (FADISC)",
        "Faculdade do Litoral Sul Paulista (FALS)",
        "Faculdade de Americana (FAM)",
        "Faculdade de Informática e Administração Paulista (FIAP)",
        "Faculdade de Direito de Itu (FADITU)",
        "Faculdades Integradas Rio Branco (FIRB)",
        "Faculdade Impacta Tecnologia (FIT)",
        "Faculdade Mario Schenberg (FMS)",
        "Faculdade Nossa Cidade (FNC)",
        "Faculdades Oswaldo Cruz (FOC)",
        "Faculdade Japi (JAPI)",
        "Faculdade de Odontologia e Centro de Pesquisas Odontológicas São Leopoldo Mandic (SLMANDIC)",
        "Faculdade de Tecnologia Tecmed (TECMED)",
        "Faculdade de Tecnologia Bandeirantes (BANDTEC)",
        "Faculdade Flamingo (FLAMINGO)",
        "Escola Superior de Engenharia e Gestão (ESEG)",
        "Universidade Federal de Sergipe (UFS)",
        "Instituto Federal de Sergipe (IFS)",
        "Universidade Tiradentes (Unit)",
        "Faculdade Pio Décimo  (FPD)",
        "Faculdade Estácio de Sergipe  (ESTÁCIO)",
        "Faculdade de Administração e Negócios de Sergipe  (FANESE)",
        "Faculdade Amadeus  (FAMA)",
        "Faculdade São Luis de França  (FSLF)",
        "Universidade Norte do Paraná  (UNOPAR)",
        "Faculdade Sergipana  (FASER)",
        "Faculdade de Aracaju  (FACAR)",
        "Faculdade Serigy  (UNIRB)",
        "Faculdade Jardins  (FAJAR)",
        "Faculdade Atlântico  (FA)",
        "Universidade do Planalto de Brasília (UNIPLAN)",
        "Universidade Dom Pedro II (UNIDOM)",
        "Sistema COC de Educação (UniCOC)",
        "Faculdade Maurício de Nassau (UNINASSAU)",
        "Centro Universitário UnirG (Gurupi) (UnirG)",
        "Fundação Universidade do Tocantins (UNITINS)",
        "Instituto Federal do Tocantins (IFTO)",
        "Universidade Federal do Tocantins (UFT)",
        "Centro Universitário Católica do Tocantins (UNICATÓLICA)",
        "Faculdade Católica Dom Orione (FACDO)",
        "Faculdade de Ciências do Tocantins (FACIT)",
        "Faculdade de Educação Ciências e Letras de Paraíso (FECIPAR)",
        "Faculdade de Palmas (FAPAL)",
        "Faculdade Guaraí (FAG)",
        "Faculdade Integrada de Ensino Superior de Colinas (FIESC)",
        "Faculdade Serra do Carmo (FASEC)",
        "Instituto de Ensino e Pesquisa Objetivo (IEPO)",
        "Instituto Tocantinense Presidente Antônio Carlos (ITPAC)",
        "Centro Universitário Luterano de Palmas (CEULP/ULBRA)",
        "Instituto Tocantinense Presidente Antônio Carlos de Porto Nacional (ITPAC Porto)",
        "União Educacional de Ensino Superior do Médio Tocantins (UNEST)",
      ],
      civilStatuses: [
        "Solteiro(a)",
        "Casado(a)",
        "Separado(a)",
        "Viúvo(a)",
        "Outro",
      ],
      tipoDeAluno: [
        "Portador de Diploma",
        "Regular em outra Instituição de Ensino Superior",
        "Português para Estrangeiro - PEC - G",
        "Convênio Instituição Pública",
        "Convênio Instituição Estrangeira",
        "Programa Integrado Formação (ALUNO DE GRADUAÇÃO DA UNICAMP)",
      ],
      binaryChoice: [
        { text: "Sim", value: true },
        { text: "Não", value: false },
      ],
      nivelFormacao: [
        "Ensino Médio completo",
        "Ensino Médio incompleto",
        "Graduação completa",
        "Graduação incompleta",
        "Mestrado completo",
        "Mestrado incompleto",
        "Especialização completa",
        "Especialização incompleta",
        "Doutorado completo",
        "Doutorado incompleto",
        "Outro",
      ],
      statusUltimoSemestreEspecial: [
        "Cursou até o fim do semestre",
        "Não se matriculou",
        "Cancelou matrícula",
        "Abandonou disciplina",
      ],
      message: "",
      isProgressBarVisible: false,
      shouldAddAnotherAddress: false,
      isEmployed: false,
      isForeignerWithBrazilianNacionality: false,
      jaSeInscreveuParaAlunoEspecial: false,
    };
  },
  created() {},

  methods: {
    ...mapActions("signUpCandidate", [
      "ActionPostCandidate",
      "ActionSetCandidate",
    ]),

    submit() {
      //console.log("submit was called");
      this.$refs.observer.validate();
    },

    cancel() {
      this.$router.push({ name: "login" });
    },

    async submitForm() {
      //console.clear();
      // console.log(JSON.stringify(this.candidate, null, 2));

      this.isProgressBarVisible = true;
      try {
        // TODO: checar os campos binarios (Sim ou Nao) para verificar se nao esta enviando
        // informacoes que nao deveria nos campos opcionais como RNE, Company, endereco alternativo, etc
        const res = await this.ActionPostCandidate(
          JSON.stringify(this.candidate)
        );

        if (res.status == 201) {
          await this.ActionSetCandidate(this.candidate);
          this.color = null;        
          // this.$router.push("/login");
        }
        this.snackbar = true;
        this.snackbarMessage = res.body.messages[0].message;

      } catch (exception) {
        this.color = "red";
        this.snackbarMessage = exception.body.messages[0].message;
        this.snackbar = true;
      } finally {
        this.isProgressBarVisible = false;
      }
    },

    async getAddressFromAPI(cep, isPrimaryAddress) {
      var url = "https://viacep.com.br/ws/" + cep + "/json/";
      axios
        .get(url)
        .then((res) => {
          console.log(res.data);
          if (isPrimaryAddress) {
            this.candidate.primary_state = res?.data?.uf ?? "";
            this.candidate.primary_city = res?.data?.localidade ?? "";
            this.candidate.primary_street = res?.data?.logradouro ?? "";
          } else {
            this.candidate.secondary_state = res?.data?.uf ?? "";
            this.candidate.secondary_city = res?.data?.localidade ?? "";
            this.candidate.secondary_street = res?.data?.logradouro ?? "";
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    fillWithTestValues() {
      this.candidate.raCandidate = "AAAAAAAA";
      this.candidate.name = "Testoncio da Silva";
      this.candidate.email = "testoncio@gmail.com";
      this.candidate.password = "123456";
      this.passwordConfirmation = "123456";
      this.candidate.cpf = "892.824.390-46";
      this.candidate.foreignId = "";
      this.candidate.sex = "Prefiro não informar";
      this.candidate.birthDate = "01/01/2000";
      this.candidate.country = "Brasil";
      this.candidate.civilStatus = "Casado(a)";
      this.isEmployed = true;
      this.candidate.company = "Testes S.A.";
      this.candidate.primary_cell_phone = "(11) 11111-11111";
      this.candidate.primary_cep = "01001-001";
      this.candidate.primary_country = "Brasil";
      this.candidate.primary_state = "SP";
      this.candidate.primary_city = "São Paulo";
      this.candidate.primary_street = "rua teste";
      this.candidate.primary_home_number = "11";
      this.candidate.primary_complement = "";
      this.shouldAddAnotherAddress = true;
      this.candidate.secondary_cell_phone = "(11) 11111-11111";
      this.candidate.secondary_cep = "01001-001";
      this.candidate.secondary_country = "Brasil";
      this.candidate.secondary_state = "SP";
      this.candidate.secondary_city = "São Paulo";
      this.candidate.secondary_street = "rua teste";
      this.candidate.secondary_home_number = "11";
      this.candidate.secondary_complement = "";
      this.candidate.tipoDeAluno = "Portador de Diploma";
      this.candidate.nivelFormacao = "Mestrado completo";
      this.candidate.nomeDaInstituicao = "UNICAMP";
      this.candidate.cursoSuperior = "Engenharia de Pesca";
      this.candidate.cidadeInstituicao = "cabreuva";
      this.candidate.estadoInstituicao = "SP";
      this.candidate.paisInstituicao = "Dinamarca";
      this.candidate.dataConclusao = "01/01/2001";
      this.candidate.statusUltimoSemestreEspecial =
        "Cursou até o fim do semestre";
      this.candidate.numeroSemestreAtual = "5";
      this.jaSeInscreveuParaAlunoEspecial = true;
    },

    hasLoggedOut() {},
  },
};
</script>

<style>
.avatar {
  text-align: center;
  margin: 0 auto;
}
</style>
