var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        { attrs: { app: "", "clipped-left": "" } },
        [
          _c("v-spacer"),
          _c(
            "v-toolbar-title",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-avatar",
                        {
                          staticClass: "mx-auto avatar",
                          attrs: { height: "30px", width: "30px" }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/logo_ic.png"),
                              alt: "logo"
                            }
                          })
                        ]
                      )
                    ],
                    1
                  ),
                  _c("v-col", [
                    _c("span", [
                      _vm._v(
                        "SiGPos - Sistema de Gerenciamento da Pós-Graduação do IC-Unicamp"
                      )
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c("v-spacer")
        ],
        1
      ),
      _c(
        "v-main",
        { staticClass: "mt-5" },
        [
          _c("validation-observer", {
            ref: "observer",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var invalid = ref.invalid
                  return [
                    _c(
                      "v-container",
                      [
                        _c(
                          "v-card",
                          { attrs: { outlined: "" } },
                          [
                            _c(
                              "v-card-text",
                              [
                                _c(
                                  "v-container",
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "title text-center" },
                                      [
                                        _vm._v(
                                          " Inscrição de aluno especial de pós graduação "
                                        )
                                      ]
                                    ),
                                    _c("v-divider"),
                                    _c("br"),
                                    _c(
                                      "form",
                                      {
                                        attrs: { autocomplete: "off" },
                                        on: {
                                          submit: function($event) {
                                            $event.preventDefault()
                                            return _vm.submit($event)
                                          }
                                        }
                                      },
                                      [
                                        _c("v-checkbox", {
                                          attrs: {
                                            label: "Usar dados de teste"
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.fillWithTestValues()
                                            }
                                          }
                                        }),
                                        _c(
                                          "v-card",
                                          { attrs: { outlined: "" } },
                                          [
                                            _c(
                                              "v-card-title",
                                              {
                                                staticClass:
                                                  "blue lighten-5 mb-2"
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "mr-2",
                                                    attrs: {
                                                      large: "",
                                                      color: "blue"
                                                    }
                                                  },
                                                  [_vm._v("person")]
                                                ),
                                                _c(
                                                  "h5",
                                                  { staticClass: "title" },
                                                  [_vm._v("Dados Pessoais")]
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-card-text",
                                              { staticClass: "grey lighten-5" },
                                              [
                                                _c(
                                                  "v-row",
                                                  {
                                                    attrs: {
                                                      wrap: "",
                                                      justify: "start",
                                                      align: "start",
                                                      dense: ""
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          sm: "12",
                                                          md: "2"
                                                        }
                                                      },
                                                      [
                                                        _c("v-text-field", {
                                                          attrs: {
                                                            outlined: "",
                                                            block: "",
                                                            label: "RA",
                                                            placeholder:
                                                              "RA, se for estudante Unicamp"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.candidate
                                                                .raCandidate,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.candidate,
                                                                "raCandidate",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "candidate.raCandidate"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          sm: "12",
                                                          md: "6"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "validation-provider",
                                                          {
                                                            attrs: {
                                                              name: "Name",
                                                              mode: "eager",
                                                              autocomplete:
                                                                "false",
                                                              rules:
                                                                "required|min:6"
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "default",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var errors =
                                                                      ref.errors
                                                                    return [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          attrs: {
                                                                            outlined:
                                                                              "",
                                                                            block:
                                                                              "",
                                                                            label:
                                                                              "Nome completo*",
                                                                            placeholder:
                                                                              "Nome do completo do candidato",
                                                                            "error-messages": errors,
                                                                            isProgressBarVisible:
                                                                              _vm.isProgressBarVisible
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm
                                                                                .candidate
                                                                                .name,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.candidate,
                                                                                "name",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "candidate.name"
                                                                          }
                                                                        }
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          sm: "12",
                                                          md: "4"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "validation-provider",
                                                          {
                                                            attrs: {
                                                              name: "email",
                                                              mode: "eager",
                                                              rules:
                                                                "required|email"
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "default",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var errors =
                                                                      ref.errors
                                                                    return [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          attrs: {
                                                                            outlined:
                                                                              "",
                                                                            block:
                                                                              "",
                                                                            label:
                                                                              "E-mail*",
                                                                            "error-messages": errors,
                                                                            isProgressBarVisible:
                                                                              _vm.isProgressBarVisible
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm
                                                                                .candidate
                                                                                .email,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.candidate,
                                                                                "email",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "candidate.email"
                                                                          }
                                                                        }
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          sm: "12",
                                                          md: "3"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "validation-provider",
                                                          {
                                                            attrs: {
                                                              name: "Password",
                                                              mode: "eager",
                                                              vid:
                                                                "candidate.password",
                                                              rules:
                                                                "required|min:6"
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "default",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var errors =
                                                                      ref.errors
                                                                    return [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          attrs: {
                                                                            outlined:
                                                                              "",
                                                                            block:
                                                                              "",
                                                                            label:
                                                                              "Senha*",
                                                                            type:
                                                                              "password",
                                                                            "error-messages": errors,
                                                                            isProgressBarVisible:
                                                                              _vm.isProgressBarVisible
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm
                                                                                .candidate
                                                                                .password,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.candidate,
                                                                                "password",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "candidate.password"
                                                                          }
                                                                        }
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          sm: "12",
                                                          md: "2"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "validation-provider",
                                                          {
                                                            attrs: {
                                                              name: "birthDate",
                                                              mode: "eager",
                                                              rules: "required"
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "default",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var errors =
                                                                      ref.errors
                                                                    return [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          directives: [
                                                                            {
                                                                              name:
                                                                                "mask",
                                                                              rawName:
                                                                                "v-mask",
                                                                              value:
                                                                                "##/##/####",
                                                                              expression:
                                                                                "'##/##/####'"
                                                                            }
                                                                          ],
                                                                          attrs: {
                                                                            outlined:
                                                                              "",
                                                                            block:
                                                                              "",
                                                                            label:
                                                                              "Data de nascimento*",
                                                                            "error-messages": errors,
                                                                            hint:
                                                                              "Formato: dd/mm/aaaa",
                                                                            isProgressBarVisible:
                                                                              _vm.isProgressBarVisible
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm
                                                                                .candidate
                                                                                .birthDate,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.candidate,
                                                                                "birthDate",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "candidate.birthDate"
                                                                          }
                                                                        }
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          sm: "12",
                                                          md: "2"
                                                        }
                                                      },
                                                      [
                                                        _c("v-select", {
                                                          attrs: {
                                                            outlined: "",
                                                            items: _vm.sexes,
                                                            label: "Sexo*"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.candidate.sex,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.candidate,
                                                                "sex",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "candidate.sex"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          sm: "12",
                                                          md: "2"
                                                        }
                                                      },
                                                      [
                                                        _c("v-select", {
                                                          attrs: {
                                                            outlined: "",
                                                            items:
                                                              _vm.civilStatuses,
                                                            label:
                                                              "Estado Civil*"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.candidate
                                                                .civilStatus,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.candidate,
                                                                "civilStatus",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "candidate.civilStatus"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          sm: "12",
                                                          md: "2"
                                                        }
                                                      },
                                                      [
                                                        _c("v-select", {
                                                          attrs: {
                                                            outlined: "",
                                                            items:
                                                              _vm.binaryChoice,
                                                            label:
                                                              "Atualmente, está trabalhando?*"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.isEmployed,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.isEmployed = $$v
                                                            },
                                                            expression:
                                                              "isEmployed"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          sm: "12",
                                                          md: "4"
                                                        }
                                                      },
                                                      [
                                                        _vm.isEmployed === true
                                                          ? _c("v-text-field", {
                                                              attrs: {
                                                                outlined: "",
                                                                block: "",
                                                                label:
                                                                  "Nome da Empresa*",
                                                                isProgressBarVisible:
                                                                  _vm.isProgressBarVisible
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.candidate
                                                                    .company,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.candidate,
                                                                    "company",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "candidate.company"
                                                              }
                                                            })
                                                          : _vm._e()
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          sm: "12",
                                                          md: "2"
                                                        }
                                                      },
                                                      [
                                                        _c("v-autocomplete", {
                                                          attrs: {
                                                            outlined: "",
                                                            items:
                                                              _vm.countries,
                                                            attach: "",
                                                            label:
                                                              "País de origem*",
                                                            placeholder:
                                                              "Escolha um país"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.candidate
                                                                .country,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.candidate,
                                                                "country",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "candidate.country"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _vm.candidate.country ===
                                                    "Brasil"
                                                      ? _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              sm: "12",
                                                              md: "3"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "validation-provider",
                                                              {
                                                                attrs: {
                                                                  name: "cpf",
                                                                  mode: "eager",
                                                                  rules:
                                                                    "required|cpf"
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "default",
                                                                      fn: function(
                                                                        ref
                                                                      ) {
                                                                        var errors =
                                                                          ref.errors
                                                                        return [
                                                                          _c(
                                                                            "v-text-field",
                                                                            {
                                                                              directives: [
                                                                                {
                                                                                  name:
                                                                                    "mask",
                                                                                  rawName:
                                                                                    "v-mask",
                                                                                  value:
                                                                                    "###.###.###-##",
                                                                                  expression:
                                                                                    "'###.###.###-##'"
                                                                                }
                                                                              ],
                                                                              attrs: {
                                                                                outlined:
                                                                                  "",
                                                                                block:
                                                                                  "",
                                                                                label:
                                                                                  "CPF*",
                                                                                "error-messages": errors,
                                                                                isProgressBarVisible:
                                                                                  _vm.isProgressBarVisible
                                                                              },
                                                                              model: {
                                                                                value:
                                                                                  _vm
                                                                                    .candidate
                                                                                    .cpf,
                                                                                callback: function(
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.candidate,
                                                                                    "cpf",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                                expression:
                                                                                  "candidate.cpf"
                                                                              }
                                                                            }
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.candidate.country !==
                                                    "Brasil"
                                                      ? _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              sm: "12",
                                                              md: "5"
                                                            }
                                                          },
                                                          [
                                                            _c("v-select", {
                                                              attrs: {
                                                                outlined: "",
                                                                items:
                                                                  _vm.binaryChoice,
                                                                label:
                                                                  "Adquiriu nacionalidade brasileira?*"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.isForeignerWithBrazilianNacionality,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.isForeignerWithBrazilianNacionality = $$v
                                                                },
                                                                expression:
                                                                  "isForeignerWithBrazilianNacionality"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.candidate.country !==
                                                    "Brasil"
                                                      ? _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              sm: "12",
                                                              md: "5"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "validation-provider",
                                                              {
                                                                attrs: {
                                                                  name:
                                                                    "rne/crnm",
                                                                  mode: "eager",
                                                                  rules:
                                                                    "required"
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "default",
                                                                      fn: function(
                                                                        ref
                                                                      ) {
                                                                        var errors =
                                                                          ref.errors
                                                                        return [
                                                                          _c(
                                                                            "v-text-field",
                                                                            {
                                                                              attrs: {
                                                                                outlined:
                                                                                  "",
                                                                                block:
                                                                                  "",
                                                                                label:
                                                                                  "RNE/CRNM*",
                                                                                "error-messages": errors,
                                                                                isProgressBarVisible:
                                                                                  _vm.isProgressBarVisible
                                                                              },
                                                                              model: {
                                                                                value:
                                                                                  _vm
                                                                                    .candidate
                                                                                    .foreignId,
                                                                                callback: function(
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.candidate,
                                                                                    "foreignId",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                                expression:
                                                                                  "candidate.foreignId"
                                                                              }
                                                                            }
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e()
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-card",
                                          {
                                            staticClass: "mt-15",
                                            attrs: { outlined: "" }
                                          },
                                          [
                                            _c(
                                              "v-card-title",
                                              {
                                                staticClass:
                                                  "amber lighten-5 mb-2"
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "mr-2",
                                                    attrs: {
                                                      color: "amber",
                                                      large: ""
                                                    }
                                                  },
                                                  [_vm._v("mail")]
                                                ),
                                                _c(
                                                  "h5",
                                                  { staticClass: "title" },
                                                  [
                                                    _vm._v(
                                                      " Dados para envio de correspondência "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-card-text",
                                              { staticClass: "grey lighten-5" },
                                              [
                                                _c(
                                                  "v-row",
                                                  {
                                                    attrs: {
                                                      wrap: "",
                                                      justify: "start",
                                                      align: "end",
                                                      dense: ""
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          sm: "12",
                                                          md: "2"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "validation-provider",
                                                          {
                                                            attrs: {
                                                              name: "cep",
                                                              mode: "eager",
                                                              rules: "cep"
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "default",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var errors =
                                                                      ref.errors
                                                                    return [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          directives: [
                                                                            {
                                                                              name:
                                                                                "mask",
                                                                              rawName:
                                                                                "v-mask",
                                                                              value:
                                                                                "#####-###",
                                                                              expression:
                                                                                "'#####-###'"
                                                                            }
                                                                          ],
                                                                          attrs: {
                                                                            outlined:
                                                                              "",
                                                                            block:
                                                                              "",
                                                                            label:
                                                                              "CEP*",
                                                                            "error-messages": errors,
                                                                            isProgressBarVisible:
                                                                              _vm.isProgressBarVisible
                                                                          },
                                                                          on: {
                                                                            change: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.getAddressFromAPI(
                                                                                _vm
                                                                                  .candidate
                                                                                  .primary_cep,
                                                                                true
                                                                              )
                                                                            }
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm
                                                                                .candidate
                                                                                .primary_cep,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.candidate,
                                                                                "primary_cep",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "candidate.primary_cep"
                                                                          }
                                                                        }
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          sm: "12",
                                                          md: "2"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "validation-provider",
                                                          {
                                                            attrs: {
                                                              name: "estado",
                                                              mode: "eager"
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "default",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var errors =
                                                                      ref.errors
                                                                    return [
                                                                      _c(
                                                                        "v-autocomplete",
                                                                        {
                                                                          attrs: {
                                                                            outlined:
                                                                              "",
                                                                            items:
                                                                              _vm.ufs,
                                                                            attach:
                                                                              "",
                                                                            "item-text":
                                                                              "UF",
                                                                            label:
                                                                              "UF*",
                                                                            "error-messages": errors
                                                                          },
                                                                          scopedSlots: _vm._u(
                                                                            [
                                                                              {
                                                                                key:
                                                                                  "no-data",
                                                                                fn: function() {
                                                                                  return [
                                                                                    _c(
                                                                                      "v-list-item",
                                                                                      [
                                                                                        _c(
                                                                                          "v-list-item-title",
                                                                                          [
                                                                                            _vm._v(
                                                                                              " UF não encontrada. "
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      ],
                                                                                      1
                                                                                    )
                                                                                  ]
                                                                                },
                                                                                proxy: true
                                                                              }
                                                                            ],
                                                                            null,
                                                                            true
                                                                          ),
                                                                          model: {
                                                                            value:
                                                                              _vm
                                                                                .candidate
                                                                                .primary_state,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.candidate,
                                                                                "primary_state",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "candidate.primary_state"
                                                                          }
                                                                        }
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          sm: "12",
                                                          md: "8"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "validation-provider",
                                                          {
                                                            attrs: {
                                                              name: "cidade",
                                                              mode: "eager"
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "default",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var errors =
                                                                      ref.errors
                                                                    return [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          attrs: {
                                                                            outlined:
                                                                              "",
                                                                            block:
                                                                              "",
                                                                            label:
                                                                              "Cidade*",
                                                                            "error-messages": errors,
                                                                            isProgressBarVisible:
                                                                              _vm.isProgressBarVisible
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm
                                                                                .candidate
                                                                                .primary_city,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.candidate,
                                                                                "primary_city",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "candidate.primary_city"
                                                                          }
                                                                        }
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          sm: "12",
                                                          md: "5"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "validation-provider",
                                                          {
                                                            attrs: {
                                                              name: "rua",
                                                              mode: "eager"
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "default",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var errors =
                                                                      ref.errors
                                                                    return [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          attrs: {
                                                                            outlined:
                                                                              "",
                                                                            block:
                                                                              "",
                                                                            label:
                                                                              "Rua/Avenida*",
                                                                            "error-messages": errors,
                                                                            isProgressBarVisible:
                                                                              _vm.isProgressBarVisible
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm
                                                                                .candidate
                                                                                .primary_street,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.candidate,
                                                                                "primary_street",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "candidate.primary_street"
                                                                          }
                                                                        }
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          sm: "12",
                                                          md: "2"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "validation-provider",
                                                          {
                                                            attrs: {
                                                              name:
                                                                "home_number",
                                                              mode: "eager"
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "default",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var errors =
                                                                      ref.errors
                                                                    return [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          directives: [
                                                                            {
                                                                              name:
                                                                                "mask",
                                                                              rawName:
                                                                                "v-mask",
                                                                              value:
                                                                                "#########",
                                                                              expression:
                                                                                "'#########'"
                                                                            }
                                                                          ],
                                                                          ref:
                                                                            "homeNumber",
                                                                          attrs: {
                                                                            outlined:
                                                                              "",
                                                                            block:
                                                                              "",
                                                                            label:
                                                                              "Número*",
                                                                            "error-messages": errors,
                                                                            isProgressBarVisible:
                                                                              _vm.isProgressBarVisible
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm
                                                                                .candidate
                                                                                .primary_home_number,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.candidate,
                                                                                "primary_home_number",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "candidate.primary_home_number"
                                                                          }
                                                                        }
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          sm: "12",
                                                          md: "5"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "validation-provider",
                                                          {
                                                            attrs: {
                                                              name:
                                                                "complemento",
                                                              mode: "eager"
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "default",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var errors =
                                                                      ref.errors
                                                                    return [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          attrs: {
                                                                            outlined:
                                                                              "",
                                                                            block:
                                                                              "",
                                                                            label:
                                                                              "Complemento",
                                                                            "error-messages": errors,
                                                                            isProgressBarVisible:
                                                                              _vm.isProgressBarVisible
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm
                                                                                .candidate
                                                                                .primary_complement,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.candidate,
                                                                                "primary_complement",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "candidate.primary_complement"
                                                                          }
                                                                        }
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          sm: "12",
                                                          md: "3"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "validation-provider",
                                                          {
                                                            attrs: {
                                                              name:
                                                                "cell_phone",
                                                              mode: "eager",
                                                              rules: "required"
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "default",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var errors =
                                                                      ref.errors
                                                                    return [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          directives: [
                                                                            {
                                                                              name:
                                                                                "mask",
                                                                              rawName:
                                                                                "v-mask",
                                                                              value:
                                                                                "(##) #####-####",
                                                                              expression:
                                                                                "'(##) #####-####'"
                                                                            }
                                                                          ],
                                                                          attrs: {
                                                                            outlined:
                                                                              "",
                                                                            block:
                                                                              "",
                                                                            label:
                                                                              "Telefone de contato*",
                                                                            "error-messages": errors,
                                                                            isProgressBarVisible:
                                                                              _vm.isProgressBarVisible
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm
                                                                                .candidate
                                                                                .primary_cell_phone,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.candidate,
                                                                                "primary_cell_phone",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "candidate.primary_cell_phone"
                                                                          }
                                                                        }
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          sm: "12",
                                                          md: "3"
                                                        }
                                                      },
                                                      [
                                                        _c("v-select", {
                                                          attrs: {
                                                            outlined: "",
                                                            items:
                                                              _vm.binaryChoice,
                                                            label:
                                                              "Gostaria de adicionar um endereço alternativo, por segurança?*"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.shouldAddAnotherAddress,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.shouldAddAnotherAddress = $$v
                                                            },
                                                            expression:
                                                              "shouldAddAnotherAddress"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _c("v-col", {
                                                      attrs: {
                                                        sm: "0",
                                                        md: "6"
                                                      }
                                                    }),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          sm: "12",
                                                          md: "2"
                                                        }
                                                      },
                                                      [
                                                        _vm.shouldAddAnotherAddress
                                                          ? _c(
                                                              "validation-provider",
                                                              {
                                                                attrs: {
                                                                  name: "cep",
                                                                  mode: "eager",
                                                                  rules: "cep"
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "default",
                                                                      fn: function(
                                                                        ref
                                                                      ) {
                                                                        var errors =
                                                                          ref.errors
                                                                        return [
                                                                          _c(
                                                                            "v-text-field",
                                                                            {
                                                                              directives: [
                                                                                {
                                                                                  name:
                                                                                    "mask",
                                                                                  rawName:
                                                                                    "v-mask",
                                                                                  value:
                                                                                    "#####-###",
                                                                                  expression:
                                                                                    "'#####-###'"
                                                                                }
                                                                              ],
                                                                              attrs: {
                                                                                outlined:
                                                                                  "",
                                                                                block:
                                                                                  "",
                                                                                label:
                                                                                  "CEP*",
                                                                                "error-messages": errors,
                                                                                isProgressBarVisible:
                                                                                  _vm.isProgressBarVisible
                                                                              },
                                                                              on: {
                                                                                change: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.getAddressFromAPI(
                                                                                    _vm
                                                                                      .candidate
                                                                                      .secondary_cep,
                                                                                    false
                                                                                  )
                                                                                }
                                                                              },
                                                                              model: {
                                                                                value:
                                                                                  _vm
                                                                                    .candidate
                                                                                    .secondary_cep,
                                                                                callback: function(
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.candidate,
                                                                                    "secondary_cep",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                                expression:
                                                                                  "candidate.secondary_cep"
                                                                              }
                                                                            }
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              }
                                                            )
                                                          : _vm._e()
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          sm: "12",
                                                          md: "1"
                                                        }
                                                      },
                                                      [
                                                        _vm.shouldAddAnotherAddress
                                                          ? _c(
                                                              "validation-provider",
                                                              {
                                                                attrs: {
                                                                  name:
                                                                    "estado",
                                                                  mode: "eager"
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "default",
                                                                      fn: function(
                                                                        ref
                                                                      ) {
                                                                        var errors =
                                                                          ref.errors
                                                                        return [
                                                                          _c(
                                                                            "v-autocomplete",
                                                                            {
                                                                              attrs: {
                                                                                outlined:
                                                                                  "",
                                                                                items:
                                                                                  _vm.ufs,
                                                                                attach:
                                                                                  "",
                                                                                "item-text":
                                                                                  "UF",
                                                                                label:
                                                                                  "UF*",
                                                                                "error-messages": errors
                                                                              },
                                                                              scopedSlots: _vm._u(
                                                                                [
                                                                                  {
                                                                                    key:
                                                                                      "no-data",
                                                                                    fn: function() {
                                                                                      return [
                                                                                        _c(
                                                                                          "v-list-item",
                                                                                          [
                                                                                            _c(
                                                                                              "v-list-item-title",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  " UF não encontrada. "
                                                                                                )
                                                                                              ]
                                                                                            )
                                                                                          ],
                                                                                          1
                                                                                        )
                                                                                      ]
                                                                                    },
                                                                                    proxy: true
                                                                                  }
                                                                                ],
                                                                                null,
                                                                                true
                                                                              ),
                                                                              model: {
                                                                                value:
                                                                                  _vm
                                                                                    .candidate
                                                                                    .secondary_state,
                                                                                callback: function(
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.candidate,
                                                                                    "secondary_state",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                                expression:
                                                                                  "candidate.secondary_state"
                                                                              }
                                                                            }
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              }
                                                            )
                                                          : _vm._e()
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          sm: "12",
                                                          md: "5"
                                                        }
                                                      },
                                                      [
                                                        _vm.shouldAddAnotherAddress
                                                          ? _c(
                                                              "validation-provider",
                                                              {
                                                                attrs: {
                                                                  name:
                                                                    "cidade",
                                                                  mode: "eager"
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "default",
                                                                      fn: function(
                                                                        ref
                                                                      ) {
                                                                        var errors =
                                                                          ref.errors
                                                                        return [
                                                                          _c(
                                                                            "v-text-field",
                                                                            {
                                                                              attrs: {
                                                                                outlined:
                                                                                  "",
                                                                                block:
                                                                                  "",
                                                                                label:
                                                                                  "Cidade*",
                                                                                "error-messages": errors,
                                                                                isProgressBarVisible:
                                                                                  _vm.isProgressBarVisible
                                                                              },
                                                                              model: {
                                                                                value:
                                                                                  _vm
                                                                                    .candidate
                                                                                    .secondary_city,
                                                                                callback: function(
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.candidate,
                                                                                    "secondary_city",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                                expression:
                                                                                  "candidate.secondary_city"
                                                                              }
                                                                            }
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              }
                                                            )
                                                          : _vm._e()
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          sm: "12",
                                                          md: "4"
                                                        }
                                                      },
                                                      [
                                                        _vm.shouldAddAnotherAddress
                                                          ? _c(
                                                              "validation-provider",
                                                              {
                                                                attrs: {
                                                                  name: "rua",
                                                                  mode: "eager"
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "default",
                                                                      fn: function(
                                                                        ref
                                                                      ) {
                                                                        var errors =
                                                                          ref.errors
                                                                        return [
                                                                          _c(
                                                                            "v-text-field",
                                                                            {
                                                                              attrs: {
                                                                                outlined:
                                                                                  "",
                                                                                block:
                                                                                  "",
                                                                                label:
                                                                                  "Rua/Avenida*",
                                                                                "error-messages": errors,
                                                                                isProgressBarVisible:
                                                                                  _vm.isProgressBarVisible
                                                                              },
                                                                              model: {
                                                                                value:
                                                                                  _vm
                                                                                    .candidate
                                                                                    .secondary_street,
                                                                                callback: function(
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.candidate,
                                                                                    "secondary_street",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                                expression:
                                                                                  "candidate.secondary_street"
                                                                              }
                                                                            }
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              }
                                                            )
                                                          : _vm._e()
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          sm: "12",
                                                          md: "2"
                                                        }
                                                      },
                                                      [
                                                        _vm.shouldAddAnotherAddress
                                                          ? _c(
                                                              "validation-provider",
                                                              {
                                                                attrs: {
                                                                  name:
                                                                    "home_number",
                                                                  mode: "eager"
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "default",
                                                                      fn: function(
                                                                        ref
                                                                      ) {
                                                                        var errors =
                                                                          ref.errors
                                                                        return [
                                                                          _c(
                                                                            "v-text-field",
                                                                            {
                                                                              directives: [
                                                                                {
                                                                                  name:
                                                                                    "mask",
                                                                                  rawName:
                                                                                    "v-mask",
                                                                                  value:
                                                                                    "#########",
                                                                                  expression:
                                                                                    "'#########'"
                                                                                }
                                                                              ],
                                                                              ref:
                                                                                "homeNumber",
                                                                              attrs: {
                                                                                outlined:
                                                                                  "",
                                                                                block:
                                                                                  "",
                                                                                label:
                                                                                  "Número*",
                                                                                "error-messages": errors,
                                                                                isProgressBarVisible:
                                                                                  _vm.isProgressBarVisible
                                                                              },
                                                                              model: {
                                                                                value:
                                                                                  _vm
                                                                                    .candidate
                                                                                    .secondary_home_number,
                                                                                callback: function(
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.candidate,
                                                                                    "secondary_home_number",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                                expression:
                                                                                  "candidate.secondary_home_number"
                                                                              }
                                                                            }
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              }
                                                            )
                                                          : _vm._e()
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          sm: "12",
                                                          md: "4"
                                                        }
                                                      },
                                                      [
                                                        _vm.shouldAddAnotherAddress
                                                          ? _c(
                                                              "validation-provider",
                                                              {
                                                                attrs: {
                                                                  name:
                                                                    "complemento",
                                                                  mode: "eager"
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "default",
                                                                      fn: function(
                                                                        ref
                                                                      ) {
                                                                        var errors =
                                                                          ref.errors
                                                                        return [
                                                                          _c(
                                                                            "v-text-field",
                                                                            {
                                                                              attrs: {
                                                                                outlined:
                                                                                  "",
                                                                                block:
                                                                                  "",
                                                                                label:
                                                                                  "Complemento",
                                                                                "error-messages": errors,
                                                                                isProgressBarVisible:
                                                                                  _vm.isProgressBarVisible
                                                                              },
                                                                              model: {
                                                                                value:
                                                                                  _vm
                                                                                    .candidate
                                                                                    .secondary_complement,
                                                                                callback: function(
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.candidate,
                                                                                    "secondary_complement",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                                expression:
                                                                                  "candidate.secondary_complement"
                                                                              }
                                                                            }
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              }
                                                            )
                                                          : _vm._e()
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          sm: "12",
                                                          md: "2"
                                                        }
                                                      },
                                                      [
                                                        _vm.shouldAddAnotherAddress
                                                          ? _c(
                                                              "validation-provider",
                                                              {
                                                                attrs: {
                                                                  name:
                                                                    "cell_phone",
                                                                  mode: "eager",
                                                                  rules:
                                                                    "required"
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "default",
                                                                      fn: function(
                                                                        ref
                                                                      ) {
                                                                        var errors =
                                                                          ref.errors
                                                                        return [
                                                                          _c(
                                                                            "v-text-field",
                                                                            {
                                                                              directives: [
                                                                                {
                                                                                  name:
                                                                                    "mask",
                                                                                  rawName:
                                                                                    "v-mask",
                                                                                  value:
                                                                                    "(##) #####-####",
                                                                                  expression:
                                                                                    "'(##) #####-####'"
                                                                                }
                                                                              ],
                                                                              attrs: {
                                                                                outlined:
                                                                                  "",
                                                                                block:
                                                                                  "",
                                                                                label:
                                                                                  "Telefone*",
                                                                                "error-messages": errors,
                                                                                isProgressBarVisible:
                                                                                  _vm.isProgressBarVisible
                                                                              },
                                                                              model: {
                                                                                value:
                                                                                  _vm
                                                                                    .candidate
                                                                                    .secondary_cell_phone,
                                                                                callback: function(
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.candidate,
                                                                                    "secondary_cell_phone",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                                expression:
                                                                                  "candidate.secondary_cell_phone"
                                                                              }
                                                                            }
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              }
                                                            )
                                                          : _vm._e()
                                                      ],
                                                      1
                                                    ),
                                                    _c("v-col", {
                                                      attrs: {
                                                        sm: "0",
                                                        md: "4"
                                                      }
                                                    }),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          sm: "12",
                                                          md: "12"
                                                        }
                                                      },
                                                      [
                                                        _c("v-select", {
                                                          attrs: {
                                                            outlined: "",
                                                            items:
                                                              _vm.tipoDeAluno,
                                                            label:
                                                              "Tipo de Aluno*",
                                                            placeholder:
                                                              "(Escolha uma opção)"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.candidate
                                                                .tipoDeAluno,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.candidate,
                                                                "tipoDeAluno",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "candidate.tipoDeAluno"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-card",
                                          {
                                            staticClass: "mt-15",
                                            attrs: { outlined: "" }
                                          },
                                          [
                                            _c(
                                              "v-card-title",
                                              {
                                                staticClass:
                                                  "green lighten-5 mb-2"
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "mr-2",
                                                    attrs: {
                                                      large: "",
                                                      color: "green"
                                                    }
                                                  },
                                                  [_vm._v("school")]
                                                ),
                                                _c(
                                                  "h5",
                                                  { staticClass: "title" },
                                                  [_vm._v("Formação")]
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-card-text",
                                              { staticClass: "grey lighten-5" },
                                              [
                                                _c(
                                                  "v-row",
                                                  {
                                                    attrs: {
                                                      wrap: "",
                                                      justify: "start",
                                                      align: "end",
                                                      dense: ""
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          sm: "12",
                                                          md: "4"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "validation-provider",
                                                          {
                                                            attrs: {
                                                              name:
                                                                "nivelFormacao",
                                                              mode: "eager"
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "default",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var errors =
                                                                      ref.errors
                                                                    return [
                                                                      _c(
                                                                        "v-autocomplete",
                                                                        {
                                                                          attrs: {
                                                                            outlined:
                                                                              "",
                                                                            items:
                                                                              _vm.nivelFormacao,
                                                                            attach:
                                                                              "",
                                                                            "item-text":
                                                                              "nivelFormacao",
                                                                            label:
                                                                              "Nível de Formação*",
                                                                            placeholder:
                                                                              "(Escolha uma opção)",
                                                                            "error-messages": errors
                                                                          },
                                                                          scopedSlots: _vm._u(
                                                                            [
                                                                              {
                                                                                key:
                                                                                  "no-data",
                                                                                fn: function() {
                                                                                  return [
                                                                                    _c(
                                                                                      "v-list-item",
                                                                                      [
                                                                                        _c(
                                                                                          "v-list-item-title",
                                                                                          [
                                                                                            _vm._v(
                                                                                              " Nível inválido. "
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      ],
                                                                                      1
                                                                                    )
                                                                                  ]
                                                                                },
                                                                                proxy: true
                                                                              }
                                                                            ],
                                                                            null,
                                                                            true
                                                                          ),
                                                                          model: {
                                                                            value:
                                                                              _vm
                                                                                .candidate
                                                                                .nivelFormacao,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.candidate,
                                                                                "nivelFormacao",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "candidate.nivelFormacao"
                                                                          }
                                                                        }
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          sm: "12",
                                                          md: "8"
                                                        }
                                                      },
                                                      [
                                                        _c("v-combobox", {
                                                          attrs: {
                                                            outlined: "",
                                                            items:
                                                              _vm.nomeDaInstituicao,
                                                            attach: "",
                                                            "item-text":
                                                              "Nome da Instituição",
                                                            label:
                                                              "Instituição de Ensino Superior",
                                                            "persistent-hint":
                                                              "",
                                                            hint:
                                                              "Formato: Nome (SIGLA)"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.candidate
                                                                .nomeDaInstituicao,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.candidate,
                                                                "nomeDaInstituicao",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "candidate.nomeDaInstituicao"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          sm: "12",
                                                          md: "5"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "validation-provider",
                                                          {
                                                            attrs: {
                                                              name:
                                                                "cursoSuperior",
                                                              mode: "eager",
                                                              rules: "required"
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "default",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var errors =
                                                                      ref.errors
                                                                    return [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          attrs: {
                                                                            outlined:
                                                                              "",
                                                                            block:
                                                                              "",
                                                                            label:
                                                                              "Curso*",
                                                                            "error-messages": errors,
                                                                            isProgressBarVisible:
                                                                              _vm.isProgressBarVisible
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm
                                                                                .candidate
                                                                                .cursoSuperior,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.candidate,
                                                                                "cursoSuperior",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "candidate.cursoSuperior"
                                                                          }
                                                                        }
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          sm: "12",
                                                          md: "5"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "validation-provider",
                                                          {
                                                            attrs: {
                                                              name:
                                                                "cidadeInstituicao",
                                                              mode: "eager",
                                                              rules: "required"
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "default",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var errors =
                                                                      ref.errors
                                                                    return [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          attrs: {
                                                                            outlined:
                                                                              "",
                                                                            block:
                                                                              "",
                                                                            label:
                                                                              "Cidade da Instituição*",
                                                                            "error-messages": errors,
                                                                            isProgressBarVisible:
                                                                              _vm.isProgressBarVisible
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm
                                                                                .candidate
                                                                                .cidadeInstituicao,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.candidate,
                                                                                "cidadeInstituicao",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "candidate.cidadeInstituicao"
                                                                          }
                                                                        }
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          sm: "12",
                                                          md: "2"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "validation-provider",
                                                          {
                                                            attrs: {
                                                              name:
                                                                "estadoInstituicao",
                                                              mode: "eager"
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "default",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var errors =
                                                                      ref.errors
                                                                    return [
                                                                      _c(
                                                                        "v-autocomplete",
                                                                        {
                                                                          attrs: {
                                                                            outlined:
                                                                              "",
                                                                            items:
                                                                              _vm.ufs,
                                                                            attach:
                                                                              "",
                                                                            "item-text":
                                                                              "UF",
                                                                            label:
                                                                              "UF Instituição*",
                                                                            "error-messages": errors
                                                                          },
                                                                          scopedSlots: _vm._u(
                                                                            [
                                                                              {
                                                                                key:
                                                                                  "no-data",
                                                                                fn: function() {
                                                                                  return [
                                                                                    _c(
                                                                                      "v-list-item",
                                                                                      [
                                                                                        _c(
                                                                                          "v-list-item-title",
                                                                                          [
                                                                                            _vm._v(
                                                                                              " UF não encontrada. "
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      ],
                                                                                      1
                                                                                    )
                                                                                  ]
                                                                                },
                                                                                proxy: true
                                                                              }
                                                                            ],
                                                                            null,
                                                                            true
                                                                          ),
                                                                          model: {
                                                                            value:
                                                                              _vm
                                                                                .candidate
                                                                                .estadoInstituicao,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.candidate,
                                                                                "estadoInstituicao",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "candidate.estadoInstituicao"
                                                                          }
                                                                        }
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          sm: "12",
                                                          md: "3"
                                                        }
                                                      },
                                                      [
                                                        _c("v-autocomplete", {
                                                          attrs: {
                                                            outlined: "",
                                                            items:
                                                              _vm.countries,
                                                            attach: "",
                                                            label:
                                                              "País Instituição*"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.candidate
                                                                .paisInstituicao,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.candidate,
                                                                "paisInstituicao",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "candidate.paisInstituicao"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          sm: "12",
                                                          md: "3"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "validation-provider",
                                                          {
                                                            attrs: {
                                                              name:
                                                                "dataConclusao",
                                                              mode: "eager",
                                                              rules: "required"
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "default",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var errors =
                                                                      ref.errors
                                                                    return [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          directives: [
                                                                            {
                                                                              name:
                                                                                "mask",
                                                                              rawName:
                                                                                "v-mask",
                                                                              value:
                                                                                "##/##/####",
                                                                              expression:
                                                                                "'##/##/####'"
                                                                            }
                                                                          ],
                                                                          attrs: {
                                                                            outlined:
                                                                              "",
                                                                            block:
                                                                              "",
                                                                            label:
                                                                              "Data de Conclusão/Previsão de Conclusão*",
                                                                            "error-messages": errors,
                                                                            hint:
                                                                              "Formato: dd/mm/aaaa",
                                                                            isProgressBarVisible:
                                                                              _vm.isProgressBarVisible
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm
                                                                                .candidate
                                                                                .dataConclusao,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.candidate,
                                                                                "dataConclusao",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "candidate.dataConclusao"
                                                                          }
                                                                        }
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          sm: "12",
                                                          md: "3"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "validation-provider",
                                                          {
                                                            attrs: {
                                                              name:
                                                                "numeroSemestreAtual",
                                                              mode: "eager"
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "default",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var errors =
                                                                      ref.errors
                                                                    return [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          directives: [
                                                                            {
                                                                              name:
                                                                                "mask",
                                                                              rawName:
                                                                                "v-mask",
                                                                              value:
                                                                                "##",
                                                                              expression:
                                                                                "'##'"
                                                                            }
                                                                          ],
                                                                          attrs: {
                                                                            outlined:
                                                                              "",
                                                                            block:
                                                                              "",
                                                                            label:
                                                                              "Número do Semestre Atual",
                                                                            "error-messages": errors,
                                                                            isProgressBarVisible:
                                                                              _vm.isProgressBarVisible
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm
                                                                                .candidate
                                                                                .numeroSemestreAtual,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.candidate,
                                                                                "numeroSemestreAtual",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "candidate.numeroSemestreAtual"
                                                                          }
                                                                        }
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          sm: "12",
                                                          md: "3"
                                                        }
                                                      },
                                                      [
                                                        _c("v-select", {
                                                          attrs: {
                                                            outlined: "",
                                                            items:
                                                              _vm.binaryChoice,
                                                            label:
                                                              "Você já se inscreveu anteriormente para aluno especial no IC?*"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.jaSeInscreveuParaAlunoEspecial,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.jaSeInscreveuParaAlunoEspecial = $$v
                                                            },
                                                            expression:
                                                              "jaSeInscreveuParaAlunoEspecial"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          sm: "12",
                                                          md: "5"
                                                        }
                                                      },
                                                      [
                                                        _vm.jaSeInscreveuParaAlunoEspecial
                                                          ? _c("v-select", {
                                                              attrs: {
                                                                outlined: "",
                                                                items:
                                                                  _vm.statusUltimoSemestreEspecial,
                                                                label:
                                                                  "Se sim, você:*",
                                                                placeholder:
                                                                  "(Escolha uma opção)"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.candidate
                                                                    .statusUltimoSemestreEspecial,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.candidate,
                                                                    "statusUltimoSemestreEspecial",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "candidate.statusUltimoSemestreEspecial"
                                                              }
                                                            })
                                                          : _vm._e()
                                                      ],
                                                      1
                                                    ),
                                                    _c("v-col", {
                                                      attrs: {
                                                        sm: "0",
                                                        md: "7"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          {
                                            staticClass: "mt-5",
                                            attrs: {
                                              wrap: "",
                                              justify: "center",
                                              align: "center"
                                            }
                                          },
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                staticClass: "text-center",
                                                attrs: {
                                                  cols: "12",
                                                  sm: "12",
                                                  md: "6"
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    staticClass: "py-6 my-5",
                                                    attrs: {
                                                      outlined: "",
                                                      color: "red accent-2",
                                                      label: "Cancelar"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.cancel()
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v("Cancelar "),
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "ml-3",
                                                        attrs: { color: "red" }
                                                      },
                                                      [_vm._v("cancel")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              {
                                                staticClass: "text-center",
                                                attrs: {
                                                  cols: "12",
                                                  sm: "12",
                                                  md: "6"
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    staticClass: "py-6 my-5",
                                                    attrs: {
                                                      outlined: "",
                                                      color: "blue accent-2",
                                                      name: "signup-btn",
                                                      label: "Cadastrar",
                                                      type: "submit",
                                                      disabled: invalid,
                                                      isProgressBarVisible:
                                                        _vm.isProgressBarVisible
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.submitForm()
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v("Cadastrar "),
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "ml-3",
                                                        attrs: { color: "blue" }
                                                      },
                                                      [_vm._v("done")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              {
                                                attrs: { cols: "12", sm: "12" }
                                              },
                                              [
                                                _c("v-progress-linear", {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        _vm.isProgressBarVisible,
                                                      expression:
                                                        "isProgressBarVisible"
                                                    }
                                                  ],
                                                  attrs: {
                                                    color: "blue accent-2",
                                                    indeterminate: ""
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              {
                                                attrs: { cols: "12", sm: "12" }
                                              },
                                              [
                                                _c(
                                                  "v-label",
                                                  { attrs: { color: "red" } },
                                                  [_vm._v(_vm._s(_vm.message))]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "v-footer",
        {
          attrs: { app: "", bottom: "", padless: "", fixed: "", elevation: "8" }
        },
        [
          _c(
            "v-col",
            { staticClass: "text-center pa-5", attrs: { cols: "12" } },
            [
              _c("div", [
                _c(
                  "span",
                  [
                    _c("v-icon", { staticClass: "mr-1" }, [_vm._v("room")]),
                    _vm._v(
                      "Albert Einstein, 1251 - Cidade Universitária, Campinas-SP CEP.: 13083-852"
                    )
                  ],
                  1
                )
              ]),
              _c("div", [
                _c(
                  "span",
                  [
                    _c(
                      "v-icon",
                      {
                        staticClass: "mr-1",
                        attrs: { small: "", color: "green" }
                      },
                      [_vm._v("phone")]
                    ),
                    _vm._v("+55 19 3521-5838")
                  ],
                  1
                ),
                _c(
                  "span",
                  [
                    _c(
                      "v-icon",
                      {
                        staticClass: "ml-10 mr-1",
                        attrs: { small: "", color: "amber" }
                      },
                      [_vm._v("mail")]
                    ),
                    _vm._v("sec-pos@ic.unicamp.br")
                  ],
                  1
                )
              ])
            ]
          )
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { color: _vm.color, timeout: _vm.timeout },
          scopedSlots: _vm._u([
            {
              key: "action",
              fn: function(ref) {
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._b(
                      {
                        attrs: { color: "white", text: "" },
                        on: {
                          click: function($event) {
                            _vm.snackbar = false
                          }
                        }
                      },
                      "v-btn",
                      attrs,
                      false
                    ),
                    [_c("v-icon", [_vm._v("clear")])],
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.snackbar,
            callback: function($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar"
          }
        },
        [_vm._v(" " + _vm._s(_vm.snackbarMessage) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }